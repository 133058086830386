export const FONT_ICON_FORMAT = {
    ICON: 'icon',
    FONT: 'font'
};

export const FONT_ICON_DEFAULT = 'all-parks-and-resorts';

/***
 * https://disneyworld.disney.go.com/debug/web-font/
 * Built with the script below. Run this in the console and capture webFontValues
 *
 * IMPORTANT NOTE: Sometimes the webfont name doesn't match the SVG. When this happens
 * you'll need to update the overrides list at the bottom of the file.
 *
 const webFontValues = Array.from(document.querySelectorAll('.webfont-char')).reduce((dest, element) => {
        const [ htmlElement, hexValue ] = element.querySelector('p').textContent.split(';');
        const name = element.querySelector('.webfont-css-class').textContent.replace('icon__', '');
        const result = {
            htmlElement: `${htmlElement};`,
            hexValue,
            name
        };
        dest[name] = result;

        return dest;
    }, {});
 */
export const WEB_FONT_VALUES = {
    'disney-resort': {
        'htmlElement': '&#57345;',
        'hexValue': 'e001',
        'name': 'disney-resort'
    },
    'california-adventure': {
        'htmlElement': '&#57346;',
        'hexValue': 'e002',
        'name': 'california-adventure'
    },
    'disney-paris': {
        'htmlElement': '&#57347;',
        'hexValue': 'e003',
        'name': 'disney-paris'
    },
    'shanghai-disney-resort': {
        'htmlElement': '&#57348;',
        'hexValue': 'e004',
        'name': 'shanghai-disney-resort'
    },
    'hong-kong-disneyland': {
        'htmlElement': '&#57349;',
        'hexValue': 'e005',
        'name': 'hong-kong-disneyland'
    },
    'tokyo-disney': {
        'htmlElement': '&#57350;',
        'hexValue': 'e006',
        'name': 'tokyo-disney'
    },
    'aulani-resort-hawaii': {
        'htmlElement': '&#57351;',
        'hexValue': 'e007',
        'name': 'aulani-resort-hawaii'
    },
    'adventure-by-disney': {
        'htmlElement': '&#57352;',
        'hexValue': 'e008',
        'name': 'adventure-by-disney'
    },
    'disney-park-generic-castle': {
        'htmlElement': '&#57353;',
        'hexValue': 'e009',
        'name': 'disney-park-generic-castle'
    },
    'leadership': {
        'htmlElement': '&#57354;',
        'hexValue': 'e00a',
        'name': 'leadership'
    },
    'selection-training': {
        'htmlElement': '&#57355;',
        'hexValue': 'e00b',
        'name': 'selection-training'
    },
    'quality-service': {
        'htmlElement': '&#57356;',
        'hexValue': 'e00c',
        'name': 'quality-service'
    },
    'brand-royalty': {
        'htmlElement': '&#57357;',
        'hexValue': 'e00d',
        'name': 'brand-royalty'
    },
    'creativity-innovate': {
        'htmlElement': '&#57358;',
        'hexValue': 'e00e',
        'name': 'creativity-innovate'
    },
    'business-excellence': {
        'htmlElement': '&#57359;',
        'hexValue': 'e00f',
        'name': 'business-excellence'
    },
    'medical-excellence': {
        'htmlElement': '&#57360;',
        'hexValue': 'e010',
        'name': 'medical-excellence'
    },
    'catalogs': {
        'htmlElement': '&#57361;',
        'hexValue': 'e011',
        'name': 'catalogs'
    },
    'disney-park': {
        'htmlElement': '&#57362;',
        'hexValue': 'e012',
        'name': 'disney-park'
    },
    'disneyland-resort': {
        'htmlElement': '&#57363;',
        'hexValue': 'e013',
        'name': 'disneyland-resort'
    },
    'city-hall-services': {
        'htmlElement': '&#57364;',
        'hexValue': 'e014',
        'name': 'city-hall-services'
    },
    'hkdl-magic-access': {
        'htmlElement': '&#57365;',
        'hexValue': 'e015',
        'name': 'hkdl-magic-access'
    },
    'downtown-disney': {
        'htmlElement': '&#57366;',
        'hexValue': 'e016',
        'name': 'downtown-disney'
    },
    'wedding-engagements': {
        'htmlElement': '&#57367;',
        'hexValue': 'e017',
        'name': 'wedding-engagements'
    },
    'weddings-ever-after-blog': {
        'htmlElement': '&#57368;',
        'hexValue': 'e018',
        'name': 'weddings-ever-after-blog'
    },
    'weddings-bridal-boutique': {
        'htmlElement': '&#57369;',
        'hexValue': 'e019',
        'name': 'weddings-bridal-boutique'
    },
    'shdr-disneytown': {
        'htmlElement': '&#57370;',
        'hexValue': 'e01a',
        'name': 'shdr-disneytown'
    },
    'shdr-wishing-star-park': {
        'htmlElement': '&#57371;',
        'hexValue': 'e01b',
        'name': 'shdr-wishing-star-park'
    },
    'shop-disney-parks-app': {
        'htmlElement': '&#57372;',
        'hexValue': 'e01c',
        'name': 'shop-disney-parks-app'
    },
    'shop-disney-parks-bag': {
        'htmlElement': '&#57373;',
        'hexValue': 'e01d',
        'name': 'shop-disney-parks-bag'
    },
    'shop-disney-bag': {
        'htmlElement': '&#57374;',
        'hexValue': 'e01e',
        'name': 'shop-disney-bag'
    },
    'style-guide': {
        'htmlElement': '&#57376;',
        'hexValue': 'e020',
        'name': 'style-guide'
    },
    'star-wars': {
        'htmlElement': '&#57377;',
        'hexValue': 'e021',
        'name': 'star-wars'
    },
    'sw-galaxys-edge-hub': {
        'htmlElement': '&#57378;',
        'hexValue': 'e022',
        'name': 'sw-galaxys-edge-hub'
    },
    'rundisney-medals': {
        'htmlElement': '&#57379;',
        'hexValue': 'e023',
        'name': 'rundisney-medals'
    },
    'rundisney-shirts': {
        'htmlElement': '&#57380;',
        'hexValue': 'e024',
        'name': 'rundisney-shirts'
    },
    'rundisney-snacks': {
        'htmlElement': '&#57381;',
        'hexValue': 'e025',
        'name': 'rundisney-snacks'
    },
    'disneyland-calender': {
        'htmlElement': '&#57382;',
        'hexValue': 'e026',
        'name': 'disneyland-calender'
    },
    'california-adventure-calender': {
        'htmlElement': '&#57383;',
        'hexValue': 'e027',
        'name': 'california-adventure-calender'
    },
    'genie-product': {
        'htmlElement': '&#57384;',
        'hexValue': 'e028',
        'name': 'genie-product'
    },
    'd23-member': {
        'htmlElement': '&#57386;',
        'hexValue': 'e02a',
        'name': 'd23-member'
    },
    'national-geographic': {
        'htmlElement': '&#57387;',
        'hexValue': 'e02b',
        'name': 'national-geographic'
    },
    'genie-lamp': {
        'htmlElement': '&#57388;',
        'hexValue': 'e02c',
        'name': 'genie-lamp'
    },
    'all-parks-and-resorts': {
        'htmlElement': '&#57408;',
        'hexValue': 'e040',
        'name': 'all-parks-and-resorts'
    },
    'magic-kingdom': {
        'htmlElement': '&#57409;',
        'hexValue': 'e041',
        'name': 'magic-kingdom'
    },
    'epcot': {
        'htmlElement': '&#57410;',
        'hexValue': 'e042',
        'name': 'epcot'
    },
    'animal-kingdom': {
        'htmlElement': '&#57411;',
        'hexValue': 'e043',
        'name': 'animal-kingdom'
    },
    'hollywood-studios': {
        'htmlElement': '&#57412;',
        'hexValue': 'e044',
        'name': 'hollywood-studios'
    },
    'typhoon-lagoon': {
        'htmlElement': '&#57413;',
        'hexValue': 'e045',
        'name': 'typhoon-lagoon'
    },
    'blizzard-beach': {
        'htmlElement': '&#57414;',
        'hexValue': 'e046',
        'name': 'blizzard-beach'
    },
    'disney-springs': {
        'htmlElement': '&#57415;',
        'hexValue': 'e047',
        'name': 'disney-springs'
    },
    'espn-wide-world-sports': {
        'htmlElement': '&#57416;',
        'hexValue': 'e048',
        'name': 'espn-wide-world-sports'
    },
    'resorts': {
        'htmlElement': '&#57417;',
        'hexValue': 'e049',
        'name': 'resorts'
    },
    'disney-beach-resorts': {
        'htmlElement': '&#57418;',
        'hexValue': 'e04a',
        'name': 'disney-beach-resorts'
    },
    'disney-boardwalk': {
        'htmlElement': '&#57419;',
        'hexValue': 'e04b',
        'name': 'disney-boardwalk'
    },
    'all-categories': {
        'htmlElement': '&#57420;',
        'hexValue': 'e04c',
        'name': 'all-categories'
    },
    'cirque-du-soleil-la-nouba': {
        'htmlElement': '&#57421;',
        'hexValue': 'e04d',
        'name': 'cirque-du-soleil-la-nouba'
    },
    'star-wars-galactic-starcruiser': {
        'htmlElement': '&#57422;',
        'hexValue': 'e04e',
        'name': 'star-wars-galactic-starcruiser'
    },
    'disney-cruise-line': {
        'htmlElement': '&#57472;',
        'hexValue': 'e080',
        'name': 'disney-cruise-line'
    },
    'dcl-our-ships': {
        'htmlElement': '&#57473;',
        'hexValue': 'e081',
        'name': 'dcl-our-ships'
    },
    'dcl-destinations': {
        'htmlElement': '&#57474;',
        'hexValue': 'e082',
        'name': 'dcl-destinations'
    },
    'dcl-onboard-fun': {
        'htmlElement': '&#57475;',
        'hexValue': 'e083',
        'name': 'dcl-onboard-fun'
    },
    'dcl-new-cruisers': {
        'htmlElement': '&#57476;',
        'hexValue': 'e084',
        'name': 'dcl-new-cruisers'
    },
    'dcl-port-adventures': {
        'htmlElement': '&#57477;',
        'hexValue': 'e085',
        'name': 'dcl-port-adventures'
    },
    'app-instructions': {
        'htmlElement': '&#57478;',
        'hexValue': 'e086',
        'name': 'app-instructions'
    },
    'featured-article': {
        'htmlElement': '&#57479;',
        'hexValue': 'e087',
        'name': 'featured-article'
    },
    'drink-of-the-day': {
        'htmlElement': '&#57480;',
        'hexValue': 'e088',
        'name': 'drink-of-the-day'
    },
    'view-folio': {
        'htmlElement': '&#57482;',
        'hexValue': 'e08a',
        'name': 'view-folio'
    },
    'key-to-the-world': {
        'htmlElement': '&#57483;',
        'hexValue': 'e08b',
        'name': 'key-to-the-world'
    },
    'in-room-entertainment': {
        'htmlElement': '&#57484;',
        'hexValue': 'e08c',
        'name': 'in-room-entertainment'
    },
    'ship-directory': {
        'htmlElement': '&#57485;',
        'hexValue': 'e08d',
        'name': 'ship-directory'
    },
    'theatre': {
        'htmlElement': '&#57486;',
        'hexValue': 'e08e',
        'name': 'theatre'
    },
    'disembarkation': {
        'htmlElement': '&#57487;',
        'hexValue': 'e08f',
        'name': 'disembarkation'
    },
    'wash-hands': {
        'htmlElement': '&#57492;',
        'hexValue': 'e094',
        'name': 'wash-hands'
    },
    'security-notice': {
        'htmlElement': '&#57493;',
        'hexValue': 'e095',
        'name': 'security-notice'
    },
    'environmental-message': {
        'htmlElement': '&#57494;',
        'hexValue': 'e096',
        'name': 'environmental-message'
    },
    'room-upgrade': {
        'htmlElement': '&#57496;',
        'hexValue': 'e098',
        'name': 'room-upgrade'
    },
    'castaway-club-member': {
        'htmlElement': '&#57498;',
        'hexValue': 'e09a',
        'name': 'castaway-club-member'
    },
    'dcl-open-house': {
        'htmlElement': '&#57499;',
        'hexValue': 'e09b',
        'name': 'dcl-open-house'
    },
    'stateroom-balcony': {
        'htmlElement': '&#57503;',
        'hexValue': 'e09f',
        'name': 'stateroom-balcony'
    },
    'dcl-uncharted-adventure': {
        'htmlElement': '&#57504;',
        'hexValue': 'e0a0',
        'name': 'dcl-uncharted-adventure'
    },
    'cart-empty': {
        'htmlElement': '&#57536;',
        'hexValue': 'e0c0',
        'name': 'cart-empty'
    },
    'cart-full': {
        'htmlElement': '&#57537;',
        'hexValue': 'e0c1',
        'name': 'cart-full'
    },
    'tickets': {
        'htmlElement': '&#57538;',
        'hexValue': 'e0c2',
        'name': 'tickets'
    },
    'fastpass-plus': {
        'htmlElement': '&#57539;',
        'hexValue': 'e0c3',
        'name': 'fastpass-plus'
    },
    'fastpasses-set': {
        'htmlElement': '&#57540;',
        'hexValue': 'e0c4',
        'name': 'fastpasses-set'
    },
    'fastpasses-copy-set': {
        'htmlElement': '&#57541;',
        'hexValue': 'e0c5',
        'name': 'fastpasses-copy-set'
    },
    'cancel-fastpasses': {
        'htmlElement': '&#57542;',
        'hexValue': 'e0c6',
        'name': 'cancel-fastpasses'
    },
    'water-park': {
        'htmlElement': '&#57543;',
        'hexValue': 'e0c7',
        'name': 'water-park'
    },
    'vacation-packages': {
        'htmlElement': '&#57544;',
        'hexValue': 'e0c8',
        'name': 'vacation-packages'
    },
    'personal-magic': {
        'htmlElement': '&#57545;',
        'hexValue': 'e0c9',
        'name': 'personal-magic'
    },
    'florida-resident': {
        'htmlElement': '&#57546;',
        'hexValue': 'e0ca',
        'name': 'florida-resident'
    },
    'disney-vacation-club-info': {
        'htmlElement': '&#57547;',
        'hexValue': 'e0cb',
        'name': 'disney-vacation-club-info'
    },
    'annual-passholder': {
        'htmlElement': '&#57548;',
        'hexValue': 'e0cc',
        'name': 'annual-passholder'
    },
    'us-military': {
        'htmlElement': '&#57549;',
        'hexValue': 'e0cd',
        'name': 'us-military'
    },
    'special-offers': {
        'htmlElement': '&#57550;',
        'hexValue': 'e0ce',
        'name': 'special-offers'
    },
    'booking-rooms-packages': {
        'htmlElement': '&#57551;',
        'hexValue': 'e0cf',
        'name': 'booking-rooms-packages'
    },
    'old-fastpass': {
        'htmlElement': '&#57552;',
        'hexValue': 'e0d0',
        'name': 'old-fastpass'
    },
    'certificate': {
        'htmlElement': '&#57553;',
        'hexValue': 'e0d1',
        'name': 'certificate'
    },
    'will-call-tickets': {
        'htmlElement': '&#57554;',
        'hexValue': 'e0d2',
        'name': 'will-call-tickets'
    },
    'entrance-pass': {
        'htmlElement': '&#57555;',
        'hexValue': 'e0d3',
        'name': 'entrance-pass'
    },
    'guest-pass': {
        'htmlElement': '&#57556;',
        'hexValue': 'e0d4',
        'name': 'guest-pass'
    },
    'city-pass': {
        'htmlElement': '&#57557;',
        'hexValue': 'e0d5',
        'name': 'city-pass'
    },
    'california': {
        'htmlElement': '&#57558;',
        'hexValue': 'e0d6',
        'name': 'california'
    },
    'southern-california': {
        'htmlElement': '&#57559;',
        'hexValue': 'e0d7',
        'name': 'southern-california'
    },
    'etickets': {
        'htmlElement': '&#57560;',
        'hexValue': 'e0d8',
        'name': 'etickets'
    },
    'compare-offers': {
        'htmlElement': '&#57561;',
        'hexValue': 'e0d9',
        'name': 'compare-offers'
    },
    'baja-california-resident': {
        'htmlElement': '&#57562;',
        'hexValue': 'e0da',
        'name': 'baja-california-resident'
    },
    'area-attractions': {
        'htmlElement': '&#57563;',
        'hexValue': 'e0db',
        'name': 'area-attractions'
    },
    'anaheim-resort-transit': {
        'htmlElement': '&#57564;',
        'hexValue': 'e0dc',
        'name': 'anaheim-resort-transit'
    },
    'edit-fastpass': {
        'htmlElement': '&#57565;',
        'hexValue': 'e0dd',
        'name': 'edit-fastpass'
    },
    'in-park-merchandise': {
        'htmlElement': '&#57568;',
        'hexValue': 'e0e0',
        'name': 'in-park-merchandise'
    },
    'travel-insurance': {
        'htmlElement': '&#57569;',
        'hexValue': 'e0e1',
        'name': 'travel-insurance'
    },
    'texas-resident': {
        'htmlElement': '&#57570;',
        'hexValue': 'e0e2',
        'name': 'texas-resident'
    },
    'candian-resident': {
        'htmlElement': '&#57571;',
        'hexValue': 'e0e3',
        'name': 'candian-resident'
    },
    'add-tickets': {
        'htmlElement': '&#57572;',
        'hexValue': 'e0e4',
        'name': 'add-tickets'
    },
    'splurge': {
        'htmlElement': '&#57573;',
        'hexValue': 'e0e5',
        'name': 'splurge'
    },
    'disneyland-annual-passes': {
        'htmlElement': '&#57574;',
        'hexValue': 'e0e6',
        'name': 'disneyland-annual-passes'
    },
    'dlr-tickets-passes': {
        'htmlElement': '&#57575;',
        'hexValue': 'e0e7',
        'name': 'dlr-tickets-passes'
    },
    'get-vacation-offer-recommended': {
        'htmlElement': '&#57576;',
        'hexValue': 'e0e8',
        'name': 'get-vacation-offer-recommended'
    },
    'get-vacation-offer-guest-created': {
        'htmlElement': '&#57577;',
        'hexValue': 'e0e9',
        'name': 'get-vacation-offer-guest-created'
    },
    'shdr-fastpass': {
        'htmlElement': '&#57578;',
        'hexValue': 'e0ea',
        'name': 'shdr-fastpass'
    },
    'currency-convertor': {
        'htmlElement': '&#57579;',
        'hexValue': 'e0eb',
        'name': 'currency-convertor'
    },
    'call-to-buy-tickets': {
        'htmlElement': '&#57580;',
        'hexValue': 'e0ec',
        'name': 'call-to-buy-tickets'
    },
    'wdw-tickets-and-passes': {
        'htmlElement': '&#57581;',
        'hexValue': 'e0ed',
        'name': 'wdw-tickets-and-passes'
    },
    'renew-annual-pass': {
        'htmlElement': '&#57582;',
        'hexValue': 'e0ee',
        'name': 'renew-annual-pass'
    },
    'renew-annual-pass-dlr': {
        'htmlElement': '&#57583;',
        'hexValue': 'e0ef',
        'name': 'renew-annual-pass-dlr'
    },
    'annual-pass-wdw': {
        'htmlElement': '&#57584;',
        'hexValue': 'e0f0',
        'name': 'annual-pass-wdw'
    },
    'mobile-order': {
        'htmlElement': '&#57585;',
        'hexValue': 'e0f1',
        'name': 'mobile-order'
    },
    'fastpass': {
        'htmlElement': '&#57586;',
        'hexValue': 'e0f2',
        'name': 'fastpass'
    },
    'ticket-ap-upgrade': {
        'htmlElement': '&#57587;',
        'hexValue': 'e0f3',
        'name': 'ticket-ap-upgrade'
    },
    'shdr-premium-access': {
        'htmlElement': '&#57588;',
        'hexValue': 'e0f4',
        'name': 'shdr-premium-access'
    },
    'shdr-tickets-and-passes': {
        'htmlElement': '&#57589;',
        'hexValue': 'e0f5',
        'name': 'shdr-tickets-and-passes'
    },
    'shdr-seasonal-pass': {
        'htmlElement': '&#57590;',
        'hexValue': 'e0f6',
        'name': 'shdr-seasonal-pass'
    },
    'shdr-fastpass-reverse': {
        'htmlElement': '&#57591;',
        'hexValue': 'e0f7',
        'name': 'shdr-fastpass-reverse'
    },
    'disney-maxpass': {
        'htmlElement': '&#57592;',
        'hexValue': 'e0f8',
        'name': 'disney-maxpass'
    },
    'shdr-premium-access-chinese': {
        'htmlElement': '&#57593;',
        'hexValue': 'e0f9',
        'name': 'shdr-premium-access-chinese'
    },
    'hawaii-resident': {
        'htmlElement': '&#57594;',
        'hexValue': 'e0fa',
        'name': 'hawaii-resident'
    },
    'club-33-membership': {
        'htmlElement': '&#57595;',
        'hexValue': 'e0fb',
        'name': 'club-33-membership'
    },
    'icon_order-grocery-delivery': {
        'htmlElement': '&#57596;',
        'hexValue': 'e0fc',
        'name': 'icon_order-grocery-delivery'
    },
    'icon_special-offers': {
        'htmlElement': '&#57597;',
        'hexValue': 'e0fd',
        'name': 'icon_special-offers'
    },
    'prebook-fastpass': {
        'htmlElement': '&#57598;',
        'hexValue': 'e0fe',
        'name': 'prebook-fastpass'
    },
    'upgrade-offer': {
        'htmlElement': '&#57599;',
        'hexValue': 'e0ff',
        'name': 'upgrade-offer'
    },
    'mdx': {
        'htmlElement': '&#57600;',
        'hexValue': 'e100',
        'name': 'mdx'
    },
    'about-me': {
        'htmlElement': '&#57601;',
        'hexValue': 'e101',
        'name': 'about-me'
    },
    'friends-family': {
        'htmlElement': '&#57602;',
        'hexValue': 'e102',
        'name': 'friends-family'
    },
    'account-settings': {
        'htmlElement': '&#57603;',
        'hexValue': 'e103',
        'name': 'account-settings'
    },
    'magicbands-v2': {
        'htmlElement': '&#57604;',
        'hexValue': 'e104',
        'name': 'magicbands-v2'
    },
    'magic-cards': {
        'htmlElement': '&#57605;',
        'hexValue': 'e105',
        'name': 'magic-cards'
    },
    'pin-payment-methods': {
        'htmlElement': '&#57606;',
        'hexValue': 'e106',
        'name': 'pin-payment-methods'
    },
    'communication-references': {
        'htmlElement': '&#57607;',
        'hexValue': 'e107',
        'name': 'communication-references'
    },
    'wishlist': {
        'htmlElement': '&#57608;',
        'hexValue': 'e108',
        'name': 'wishlist'
    },
    'link-existing-reservation': {
        'htmlElement': '&#57609;',
        'hexValue': 'e109',
        'name': 'link-existing-reservation'
    },
    'guest-entered-schedule-item': {
        'htmlElement': '&#57610;',
        'hexValue': 'e10a',
        'name': 'guest-entered-schedule-item'
    },
    'birthday': {
        'htmlElement': '&#57611;',
        'hexValue': 'e10b',
        'name': 'birthday'
    },
    'memory-maker': {
        'htmlElement': '&#57612;',
        'hexValue': 'e10c',
        'name': 'memory-maker'
    },
    'memory-maker-2': {
        'htmlElement': '&#57613;',
        'hexValue': 'e10d',
        'name': 'memory-maker-2'
    },
    'passes-and-memberships': {
        'htmlElement': '&#57614;',
        'hexValue': 'e10e',
        'name': 'passes-and-memberships'
    },
    'link-mdx-tickets-passes': {
        'htmlElement': '&#57615;',
        'hexValue': 'e10f',
        'name': 'link-mdx-tickets-passes'
    },
    'link-mdx-resort-reservations': {
        'htmlElement': '&#57616;',
        'hexValue': 'e110',
        'name': 'link-mdx-resort-reservations'
    },
    'link-mdx-dining-reservations': {
        'htmlElement': '&#57617;',
        'hexValue': 'e111',
        'name': 'link-mdx-dining-reservations'
    },
    'link-dlr-tickets': {
        'htmlElement': '&#57618;',
        'hexValue': 'e112',
        'name': 'link-dlr-tickets'
    },
    'link-main-entrance': {
        'htmlElement': '&#57619;',
        'hexValue': 'e113',
        'name': 'link-main-entrance'
    },
    'digital-key': {
        'htmlElement': '&#57621;',
        'hexValue': 'e115',
        'name': 'digital-key'
    },
    'wishlist-item-added': {
        'htmlElement': '&#57622;',
        'hexValue': 'e116',
        'name': 'wishlist-item-added'
    },
    'wishlist-empty': {
        'htmlElement': '&#57623;',
        'hexValue': 'e117',
        'name': 'wishlist-empty'
    },
    'magicbands-original': {
        'htmlElement': '&#57624;',
        'hexValue': 'e118',
        'name': 'magicbands-original'
    },
    'magic-mobile': {
        'htmlElement': '&#57625;',
        'hexValue': 'e119',
        'name': 'magic-mobile'
    },
    'magic-band-plus': {
        'htmlElement': '&#57626;',
        'hexValue': 'e11a',
        'name': 'magic-band-plus'
    },
    'all-activities': {
        'htmlElement': '&#57664;',
        'hexValue': 'e140',
        'name': 'all-activities'
    },
    'attractions': {
        'htmlElement': '&#57665;',
        'hexValue': 'e141',
        'name': 'attractions'
    },
    'characters': {
        'htmlElement': '&#57666;',
        'hexValue': 'e142',
        'name': 'characters'
    },
    'entertainment': {
        'htmlElement': '&#57667;',
        'hexValue': 'e143',
        'name': 'entertainment'
    },
    'tours-events': {
        'htmlElement': '&#57668;',
        'hexValue': 'e144',
        'name': 'tours-events'
    },
    'in-park-shopping': {
        'htmlElement': '&#57669;',
        'hexValue': 'e145',
        'name': 'in-park-shopping'
    },
    'spas': {
        'htmlElement': '&#57670;',
        'hexValue': 'e146',
        'name': 'spas'
    },
    'tour': {
        'htmlElement': '&#57676;',
        'hexValue': 'e14c',
        'name': 'tour'
    },
    'spring-break': {
        'htmlElement': '&#57677;',
        'hexValue': 'e14d',
        'name': 'spring-break'
    },
    'fireworks': {
        'htmlElement': '&#57678;',
        'hexValue': 'e14e',
        'name': 'fireworks'
    },
    'shows': {
        'htmlElement': '&#57679;',
        'hexValue': 'e14f',
        'name': 'shows'
    },
    'dining': {
        'htmlElement': '&#57728;',
        'hexValue': 'e180',
        'name': 'dining'
    },
    'dining-plan': {
        'htmlElement': '&#57729;',
        'hexValue': 'e181',
        'name': 'dining-plan'
    },
    'dining-plan-accepted': {
        'htmlElement': '&#57730;',
        'hexValue': 'e182',
        'name': 'dining-plan-accepted'
    },
    'dining-plan-not-accepted': {
        'htmlElement': '&#57731;',
        'hexValue': 'e183',
        'name': 'dining-plan-not-accepted'
    },
    'dining-reservation': {
        'htmlElement': '&#57732;',
        'hexValue': 'e184',
        'name': 'dining-reservation'
    },
    'menu': {
        'htmlElement': '&#57733;',
        'hexValue': 'e185',
        'name': 'menu'
    },
    'prix-fixe-qsr': {
        'htmlElement': '&#57734;',
        'hexValue': 'e186',
        'name': 'prix-fixe-qsr'
    },
    'prix-fixe-tsr': {
        'htmlElement': '&#57735;',
        'hexValue': 'e187',
        'name': 'prix-fixe-tsr'
    },
    'entrees-qsr': {
        'htmlElement': '&#57736;',
        'hexValue': 'e188',
        'name': 'entrees-qsr'
    },
    'entrees-tsr': {
        'htmlElement': '&#57737;',
        'hexValue': 'e189',
        'name': 'entrees-tsr'
    },
    'beverages': {
        'htmlElement': '&#57738;',
        'hexValue': 'e18a',
        'name': 'beverages'
    },
    'wine': {
        'htmlElement': '&#57739;',
        'hexValue': 'e18b',
        'name': 'wine'
    },
    'beer': {
        'htmlElement': '&#57740;',
        'hexValue': 'e18c',
        'name': 'beer'
    },
    'cocktails': {
        'htmlElement': '&#57741;',
        'hexValue': 'e18d',
        'name': 'cocktails'
    },
    'appetizers': {
        'htmlElement': '&#57742;',
        'hexValue': 'e18e',
        'name': 'appetizers'
    },
    'sandwiches': {
        'htmlElement': '&#57743;',
        'hexValue': 'e18f',
        'name': 'sandwiches'
    },
    'beef-pork-chicken': {
        'htmlElement': '&#57744;',
        'hexValue': 'e190',
        'name': 'beef-pork-chicken'
    },
    'seafood': {
        'htmlElement': '&#57745;',
        'hexValue': 'e191',
        'name': 'seafood'
    },
    'pasta': {
        'htmlElement': '&#57746;',
        'hexValue': 'e192',
        'name': 'pasta'
    },
    'salads': {
        'htmlElement': '&#57747;',
        'hexValue': 'e193',
        'name': 'salads'
    },
    'sides': {
        'htmlElement': '&#57748;',
        'hexValue': 'e194',
        'name': 'sides'
    },
    'desserts': {
        'htmlElement': '&#57749;',
        'hexValue': 'e195',
        'name': 'desserts'
    },
    'buffet': {
        'htmlElement': '&#57750;',
        'hexValue': 'e196',
        'name': 'buffet'
    },
    'favorites': {
        'htmlElement': '&#57751;',
        'hexValue': 'e197',
        'name': 'favorites'
    },
    'kids-picks': {
        'htmlElement': '&#57752;',
        'hexValue': 'e198',
        'name': 'kids-picks'
    },
    'featured-items': {
        'htmlElement': '&#57753;',
        'hexValue': 'e199',
        'name': 'featured-items'
    },
    'nutritional-content': {
        'htmlElement': '&#57754;',
        'hexValue': 'e19a',
        'name': 'nutritional-content'
    },
    'nutritional-information': {
        'htmlElement': '&#57755;',
        'hexValue': 'e19b',
        'name': 'nutritional-information'
    },
    'gluten-free': {
        'htmlElement': '&#57756;',
        'hexValue': 'e19c',
        'name': 'gluten-free'
    },
    'dairy-free': {
        'htmlElement': '&#57757;',
        'hexValue': 'e19d',
        'name': 'dairy-free'
    },
    'character-dining': {
        'htmlElement': '&#57758;',
        'hexValue': 'e19e',
        'name': 'character-dining'
    },
    'mockey-food-seal': {
        'htmlElement': '&#57759;',
        'hexValue': 'e19f',
        'name': 'mockey-food-seal'
    },
    'priority-seating': {
        'htmlElement': '&#57761;',
        'hexValue': 'e1a1',
        'name': 'priority-seating'
    },
    'vegetarian': {
        'htmlElement': '&#57762;',
        'hexValue': 'e1a2',
        'name': 'vegetarian'
    },
    'raw-food': {
        'htmlElement': '&#57763;',
        'hexValue': 'e1a3',
        'name': 'raw-food'
    },
    'guest-favorite-food': {
        'htmlElement': '&#57764;',
        'hexValue': 'e1a4',
        'name': 'guest-favorite-food'
    },
    'mobile-order-food': {
        'htmlElement': '&#57771;',
        'hexValue': 'e1ab',
        'name': 'mobile-order-food'
    },
    'adult-table-service': {
        'htmlElement': '&#57766;',
        'hexValue': 'e1a6',
        'name': 'adult-table-service'
    },
    'celebration-cakes': {
        'htmlElement': '&#57767;',
        'hexValue': 'e1a7',
        'name': 'celebration-cakes'
    },
    'disney-dining-plan': {
        'htmlElement': '&#57768;',
        'hexValue': 'e1a8',
        'name': 'disney-dining-plan'
    },
    'disney-deluxe-dining-plan': {
        'htmlElement': '&#57769;',
        'hexValue': 'e1a9',
        'name': 'disney-deluxe-dining-plan'
    },
    'coffee-mug': {
        'htmlElement': '&#57770;',
        'hexValue': 'e1aa',
        'name': 'coffee-mug'
    },
    'qs-dining-card': {
        'htmlElement': '&#57772;',
        'hexValue': 'e1ac',
        'name': 'qs-dining-card'
    },
    'allergy-friendly': {
        'htmlElement': '&#57773;',
        'hexValue': 'e1ad',
        'name': 'allergy-friendly'
    },
    'kids-meals': {
        'htmlElement': '&#57774;',
        'hexValue': 'e1ae',
        'name': 'kids-meals'
    },
    'dining-check-in': {
        'htmlElement': '&#57775;',
        'hexValue': 'e1af',
        'name': 'dining-check-in'
    },
    'sports-recreation': {
        'htmlElement': '&#57792;',
        'hexValue': 'e1c0',
        'name': 'sports-recreation'
    },
    'campfire': {
        'htmlElement': '&#57793;',
        'hexValue': 'e1c1',
        'name': 'campfire'
    },
    'snorkeling': {
        'htmlElement': '&#57794;',
        'hexValue': 'e1c2',
        'name': 'snorkeling'
    },
    'swimming': {
        'htmlElement': '&#57795;',
        'hexValue': 'e1c3',
        'name': 'swimming'
    },
    'boating': {
        'htmlElement': '&#57796;',
        'hexValue': 'e1c4',
        'name': 'boating'
    },
    'running': {
        'htmlElement': '&#57797;',
        'hexValue': 'e1c5',
        'name': 'running'
    },
    'fitness-room': {
        'htmlElement': '&#57798;',
        'hexValue': 'e1c6',
        'name': 'fitness-room'
    },
    'game': {
        'htmlElement': '&#57799;',
        'hexValue': 'e1c7',
        'name': 'game'
    },
    'shuffleboard': {
        'htmlElement': '&#57800;',
        'hexValue': 'e1c8',
        'name': 'shuffleboard'
    },
    'parasailing': {
        'htmlElement': '&#57801;',
        'hexValue': 'e1c9',
        'name': 'parasailing'
    },
    'playground': {
        'htmlElement': '&#57802;',
        'hexValue': 'e1ca',
        'name': 'playground'
    },
    'horseback-riding': {
        'htmlElement': '&#57803;',
        'hexValue': 'e1cb',
        'name': 'horseback-riding'
    },
    'pool-tables': {
        'htmlElement': '&#57804;',
        'hexValue': 'e1cc',
        'name': 'pool-tables'
    },
    'fishing': {
        'htmlElement': '&#57805;',
        'hexValue': 'e1cd',
        'name': 'fishing'
    },
    'volleyball': {
        'htmlElement': '&#57806;',
        'hexValue': 'e1ce',
        'name': 'volleyball'
    },
    'basketball': {
        'htmlElement': '&#57807;',
        'hexValue': 'e1cf',
        'name': 'basketball'
    },
    'cycling': {
        'htmlElement': '&#57808;',
        'hexValue': 'e1d0',
        'name': 'cycling'
    },
    'explore': {
        'htmlElement': '&#57809;',
        'hexValue': 'e1d1',
        'name': 'explore'
    },
    'disneyland-recreation': {
        'htmlElement': '&#57810;',
        'hexValue': 'e1d2',
        'name': 'disneyland-recreation'
    },
    'baseball': {
        'htmlElement': '&#57812;',
        'hexValue': 'e1d4',
        'name': 'baseball'
    },
    'field-hockey': {
        'htmlElement': '&#57813;',
        'hexValue': 'e1d5',
        'name': 'field-hockey'
    },
    'football': {
        'htmlElement': '&#57814;',
        'hexValue': 'e1d6',
        'name': 'football'
    },
    'gymnastics': {
        'htmlElement': '&#57815;',
        'hexValue': 'e1d7',
        'name': 'gymnastics'
    },
    'lacrosse': {
        'htmlElement': '&#57816;',
        'hexValue': 'e1d8',
        'name': 'lacrosse'
    },
    'crosscountry': {
        'htmlElement': '&#57817;',
        'hexValue': 'e1d9',
        'name': 'crosscountry'
    },
    'soccer': {
        'htmlElement': '&#57818;',
        'hexValue': 'e1da',
        'name': 'soccer'
    },
    'softball-fastpitch': {
        'htmlElement': '&#57819;',
        'hexValue': 'e1db',
        'name': 'softball-fastpitch'
    },
    'softball-slowpitch': {
        'htmlElement': '&#57820;',
        'hexValue': 'e1dc',
        'name': 'softball-slowpitch'
    },
    'track-field': {
        'htmlElement': '&#57821;',
        'hexValue': 'e1dd',
        'name': 'track-field'
    },
    'tennis-court': {
        'htmlElement': '&#57822;',
        'hexValue': 'e1de',
        'name': 'tennis-court'
    },
    'generic-sports': {
        'htmlElement': '&#57823;',
        'hexValue': 'e1df',
        'name': 'generic-sports'
    },
    'maps': {
        'htmlElement': '&#57856;',
        'hexValue': 'e200',
        'name': 'maps'
    },
    'guest-services': {
        'htmlElement': '&#57857;',
        'hexValue': 'e201',
        'name': 'guest-services'
    },
    'concierge': {
        'htmlElement': '&#57858;',
        'hexValue': 'e202',
        'name': 'concierge'
    },
    'resort-checkin': {
        'htmlElement': '&#57859;',
        'hexValue': 'e203',
        'name': 'resort-checkin'
    },
    'celebration': {
        'htmlElement': '&#57860;',
        'hexValue': 'e204',
        'name': 'celebration'
    },
    'aeds': {
        'htmlElement': '&#57861;',
        'hexValue': 'e205',
        'name': 'aeds'
    },
    'smoking-area': {
        'htmlElement': '&#57862;',
        'hexValue': 'e206',
        'name': 'smoking-area'
    },
    'no-smoking': {
        'htmlElement': '&#57863;',
        'hexValue': 'e207',
        'name': 'no-smoking'
    },
    'lost-found': {
        'htmlElement': '&#57864;',
        'hexValue': 'e208',
        'name': 'lost-found'
    },
    'baby-care-center': {
        'htmlElement': '&#57865;',
        'hexValue': 'e209',
        'name': 'baby-care-center'
    },
    'currency-exchange': {
        'htmlElement': '&#57866;',
        'hexValue': 'e20a',
        'name': 'currency-exchange'
    },
    'atm': {
        'htmlElement': '&#57867;',
        'hexValue': 'e20b',
        'name': 'atm'
    },
    'restrooms': {
        'htmlElement': '&#57868;',
        'hexValue': 'e20c',
        'name': 'restrooms'
    },
    'first-aid': {
        'htmlElement': '&#57869;',
        'hexValue': 'e20d',
        'name': 'first-aid'
    },
    'laundry': {
        'htmlElement': '&#57870;',
        'hexValue': 'e20e',
        'name': 'laundry'
    },
    'valet': {
        'htmlElement': '&#57871;',
        'hexValue': 'e20f',
        'name': 'valet'
    },
    'in-room-babysitting': {
        'htmlElement': '&#57872;',
        'hexValue': 'e210',
        'name': 'in-room-babysitting'
    },
    'business-center': {
        'htmlElement': '&#57873;',
        'hexValue': 'e211',
        'name': 'business-center'
    },
    'cabanas': {
        'htmlElement': '&#57874;',
        'hexValue': 'e212',
        'name': 'cabanas'
    },
    'ecv': {
        'htmlElement': '&#57875;',
        'hexValue': 'e213',
        'name': 'ecv'
    },
    'strollers': {
        'htmlElement': '&#57876;',
        'hexValue': 'e214',
        'name': 'strollers'
    },
    'parking-lot': {
        'htmlElement': '&#57877;',
        'hexValue': 'e215',
        'name': 'parking-lot'
    },
    'kennel': {
        'htmlElement': '&#57878;',
        'hexValue': 'e216',
        'name': 'kennel'
    },
    'package-pick-up': {
        'htmlElement': '&#57879;',
        'hexValue': 'e217',
        'name': 'package-pick-up'
    },
    'gas-station': {
        'htmlElement': '&#57880;',
        'hexValue': 'e218',
        'name': 'gas-station'
    },
    'pin-trading': {
        'htmlElement': '&#57881;',
        'hexValue': 'e219',
        'name': 'pin-trading'
    },
    'translation-devices': {
        'htmlElement': '&#57882;',
        'hexValue': 'e21a',
        'name': 'translation-devices'
    },
    'wedding-ceremonies': {
        'htmlElement': '&#57883;',
        'hexValue': 'e21b',
        'name': 'wedding-ceremonies'
    },
    'resort-amenties': {
        'htmlElement': '&#57884;',
        'hexValue': 'e21c',
        'name': 'resort-amenties'
    },
    'moveis-under-the-stars': {
        'htmlElement': '&#57885;',
        'hexValue': 'e21d',
        'name': 'moveis-under-the-stars'
    },
    'kodak-picture-spot': {
        'htmlElement': '&#57886;',
        'hexValue': 'e21e',
        'name': 'kodak-picture-spot'
    },
    'locker-rentals': {
        'htmlElement': '&#57887;',
        'hexValue': 'e21f',
        'name': 'locker-rentals'
    },
    'here-now': {
        'htmlElement': '&#57888;',
        'hexValue': 'e220',
        'name': 'here-now'
    },
    'current-location': {
        'htmlElement': '&#57889;',
        'hexValue': 'e221',
        'name': 'current-location'
    },
    'supervise-children': {
        'htmlElement': '&#57890;',
        'hexValue': 'e222',
        'name': 'supervise-children'
    },
    'rider-swap': {
        'htmlElement': '&#57891;',
        'hexValue': 'e223',
        'name': 'rider-swap'
    },
    'service-dog': {
        'htmlElement': '&#57892;',
        'hexValue': 'e224',
        'name': 'service-dog'
    },
    'free-disney-wifi': {
        'htmlElement': '&#57893;',
        'hexValue': 'e225',
        'name': 'free-disney-wifi'
    },
    'generic-wifi': {
        'htmlElement': '&#57894;',
        'hexValue': 'e226',
        'name': 'generic-wifi'
    },
    'location-pin': {
        'htmlElement': '&#58476;',
        'hexValue': 'e46c',
        'name': 'location-pin'
    },
    'disney-express-luggage': {
        'htmlElement': '&#57901;',
        'hexValue': 'e22d',
        'name': 'disney-express-luggage'
    },
    'picnic-area': {
        'htmlElement': '&#57904;',
        'hexValue': 'e230',
        'name': 'picnic-area'
    },
    'nikon-picture-spot': {
        'htmlElement': '&#57910;',
        'hexValue': 'e236',
        'name': 'nikon-picture-spot'
    },
    'atm-2': {
        'htmlElement': '&#57911;',
        'hexValue': 'e237',
        'name': 'atm-2'
    },
    'drinking-fountains': {
        'htmlElement': '&#57912;',
        'hexValue': 'e238',
        'name': 'drinking-fountains'
    },
    'package-express': {
        'htmlElement': '&#57913;',
        'hexValue': 'e239',
        'name': 'package-express'
    },
    'kingdom-club-bellman': {
        'htmlElement': '&#57914;',
        'hexValue': 'e23a',
        'name': 'kingdom-club-bellman'
    },
    'in-park-info': {
        'htmlElement': '&#57915;',
        'hexValue': 'e23b',
        'name': 'in-park-info'
    },
    'magical-coin-press-machine': {
        'htmlElement': '&#57916;',
        'hexValue': 'e23c',
        'name': 'magical-coin-press-machine'
    },
    'stroller-parking': {
        'htmlElement': '&#57917;',
        'hexValue': 'e23d',
        'name': 'stroller-parking'
    },
    'electric-car-charging-station': {
        'htmlElement': '&#57918;',
        'hexValue': 'e23e',
        'name': 'electric-car-charging-station'
    },
    'parade-route-show-area': {
        'htmlElement': '&#57919;',
        'hexValue': 'e23f',
        'name': 'parade-route-show-area'
    },
    'alert': {
        'htmlElement': '&#57920;',
        'hexValue': 'e240',
        'name': 'alert'
    },
    'checkmark': {
        'htmlElement': '&#57921;',
        'hexValue': 'e241',
        'name': 'checkmark'
    },
    'faqs': {
        'htmlElement': '&#57922;',
        'hexValue': 'e242',
        'name': 'faqs'
    },
    'quick-answer': {
        'htmlElement': '&#57923;',
        'hexValue': 'e243',
        'name': 'quick-answer'
    },
    'disney-parks-blog': {
        'htmlElement': '&#57924;',
        'hexValue': 'e244',
        'name': 'disney-parks-blog'
    },
    'sms': {
        'htmlElement': '&#57925;',
        'hexValue': 'e245',
        'name': 'sms'
    },
    'press-center': {
        'htmlElement': '&#57926;',
        'hexValue': 'e246',
        'name': 'press-center'
    },
    'moms-panel': {
        'htmlElement': '&#57927;',
        'hexValue': 'e247',
        'name': 'moms-panel'
    },
    'park-schedule': {
        'htmlElement': '&#57928;',
        'hexValue': 'e248',
        'name': 'park-schedule'
    },
    'time': {
        'htmlElement': '&#57929;',
        'hexValue': 'e249',
        'name': 'time'
    },
    'privacy-policies': {
        'htmlElement': '&#57930;',
        'hexValue': 'e24a',
        'name': 'privacy-policies'
    },
    'terms-conditions': {
        'htmlElement': '&#57931;',
        'hexValue': 'e24b',
        'name': 'terms-conditions'
    },
    'accessibility': {
        'htmlElement': '&#57932;',
        'hexValue': 'e24c',
        'name': 'accessibility'
    },
    'pre-arrival-tips': {
        'htmlElement': '&#57933;',
        'hexValue': 'e24d',
        'name': 'pre-arrival-tips'
    },
    'reservations': {
        'htmlElement': '&#57934;',
        'hexValue': 'e24e',
        'name': 'reservations'
    },
    'registration': {
        'htmlElement': '&#57935;',
        'hexValue': 'e24f',
        'name': 'registration'
    },
    'help-support': {
        'htmlElement': '&#57936;',
        'hexValue': 'e250',
        'name': 'help-support'
    },
    'learn-more': {
        'htmlElement': '&#57937;',
        'hexValue': 'e251',
        'name': 'learn-more'
    },
    'email': {
        'htmlElement': '&#57938;',
        'hexValue': 'e252',
        'name': 'email'
    },
    'chat': {
        'htmlElement': '&#57939;',
        'hexValue': 'e253',
        'name': 'chat'
    },
    'call': {
        'htmlElement': '&#57940;',
        'hexValue': 'e254',
        'name': 'call'
    },
    '14-days-notice': {
        'htmlElement': '&#57941;',
        'hexValue': 'e255',
        'name': '14-days-notice'
    },
    '6-months-notice': {
        'htmlElement': '&#57942;',
        'hexValue': 'e256',
        'name': '6-months-notice'
    },
    'blackout-days': {
        'htmlElement': '&#57943;',
        'hexValue': 'e257',
        'name': 'blackout-days'
    },
    '3-days-notice': {
        'htmlElement': '&#57944;',
        'hexValue': 'e258',
        'name': '3-days-notice'
    },
    'website-support': {
        'htmlElement': '&#57945;',
        'hexValue': 'e259',
        'name': 'website-support'
    },
    'restriction': {
        'htmlElement': '&#57946;',
        'hexValue': 'e25a',
        'name': 'restriction'
    },
    'recommendation': {
        'htmlElement': '&#57947;',
        'hexValue': 'e25b',
        'name': 'recommendation'
    },
    'na-dash': {
        'htmlElement': '&#57948;',
        'hexValue': 'e25c',
        'name': 'na-dash'
    },
    'magic-morning': {
        'htmlElement': '&#57949;',
        'hexValue': 'e25d',
        'name': 'magic-morning'
    },
    'global-languages': {
        'htmlElement': '&#57950;',
        'hexValue': 'e25e',
        'name': 'global-languages'
    },
    'good-neighbor-hotel': {
        'htmlElement': '&#57951;',
        'hexValue': 'e25f',
        'name': 'good-neighbor-hotel'
    },
    'travel-information': {
        'htmlElement': '&#57953;',
        'hexValue': 'e261',
        'name': 'travel-information'
    },
    'visiting-with-groups': {
        'htmlElement': '&#57954;',
        'hexValue': 'e262',
        'name': 'visiting-with-groups'
    },
    'visiting-with-children': {
        'htmlElement': '&#57955;',
        'hexValue': 'e263',
        'name': 'visiting-with-children'
    },
    'disney-like-light': {
        'htmlElement': '&#57958;',
        'hexValue': 'e266',
        'name': 'disney-like-light'
    },
    'disney-like-dark': {
        'htmlElement': '&#57959;',
        'hexValue': 'e267',
        'name': 'disney-like-dark'
    },
    'written-aids': {
        'htmlElement': '&#57960;',
        'hexValue': 'e268',
        'name': 'written-aids'
    },
    'generations-travel': {
        'htmlElement': '&#57961;',
        'hexValue': 'e269',
        'name': 'generations-travel'
    },
    'empty-chat': {
        'htmlElement': '&#57962;',
        'hexValue': 'e26a',
        'name': 'empty-chat'
    },
    'filled-chat': {
        'htmlElement': '&#57963;',
        'hexValue': 'e26b',
        'name': 'filled-chat'
    },
    'empty-checkbox': {
        'htmlElement': '&#57964;',
        'hexValue': 'e26c',
        'name': 'empty-checkbox'
    },
    'selected-solid-checkbox': {
        'htmlElement': '&#57965;',
        'hexValue': 'e26d',
        'name': 'selected-solid-checkbox'
    },
    'selected-disabled': {
        'htmlElement': '&#57966;',
        'hexValue': 'e26e',
        'name': 'selected-disabled'
    },
    'indeterminate': {
        'htmlElement': '&#57967;',
        'hexValue': 'e26f',
        'name': 'indeterminate'
    },
    '10-days-notice': {
        'htmlElement': '&#57968;',
        'hexValue': 'e270',
        'name': '10-days-notice'
    },
    'wdw-ap-blockout-cal': {
        'htmlElement': '&#57969;',
        'hexValue': 'e271',
        'name': 'wdw-ap-blockout-cal'
    },
    'flexible-ticket': {
        'htmlElement': '&#57970;',
        'hexValue': 'e272',
        'name': 'flexible-ticket'
    },
    'icon_ride-height': {
        'htmlElement': '&#57973;',
        'hexValue': 'e275',
        'name': 'icon_ride-height'
    },
    'adults': {
        'htmlElement': '&#57974;',
        'hexValue': 'e276',
        'name': 'adults'
    },
    'teens-tweens': {
        'htmlElement': '&#57975;',
        'hexValue': 'e277',
        'name': 'teens-tweens'
    },
    'kids': {
        'htmlElement': '&#57976;',
        'hexValue': 'e278',
        'name': 'kids'
    },
    'price-range': {
        'htmlElement': '&#57977;',
        'hexValue': 'e279',
        'name': 'price-range'
    },
    'price-range-outlined': {
        'htmlElement': '&#57978;',
        'hexValue': 'e27a',
        'name': 'price-range-outlined'
    },
    'time-outlined': {
        'htmlElement': '&#57979;',
        'hexValue': 'e27b',
        'name': 'time-outlined'
    },
    'all-parks-available': {
        'htmlElement': '&#57980;',
        'hexValue': 'e27c',
        'name': 'all-parks-available'
    },
    'some-parks-available': {
        'htmlElement': '&#57981;',
        'hexValue': 'e27d',
        'name': 'some-parks-available'
    },
    'notification': {
        'htmlElement': '&#57982;',
        'hexValue': 'e27e',
        'name': 'notification'
    },
    'checkbox-backplate': {
        'htmlElement': '&#57983;',
        'hexValue': 'e27f',
        'name': 'checkbox-backplate'
    },
    'my-media': {
        'htmlElement': '&#57984;',
        'hexValue': 'e280',
        'name': 'my-media'
    },
    'images': {
        'htmlElement': '&#57985;',
        'hexValue': 'e281',
        'name': 'images'
    },
    'videos': {
        'htmlElement': '&#57986;',
        'hexValue': 'e282',
        'name': 'videos'
    },
    'photo-pass': {
        'htmlElement': '&#57987;',
        'hexValue': 'e283',
        'name': 'photo-pass'
    },
    'photo-pass-plus': {
        'htmlElement': '&#57988;',
        'hexValue': 'e284',
        'name': 'photo-pass-plus'
    },
    'media-favorites': {
        'htmlElement': '&#57989;',
        'hexValue': 'e285',
        'name': 'media-favorites'
    },
    'photo-fun': {
        'htmlElement': '&#57990;',
        'hexValue': 'e286',
        'name': 'photo-fun'
    },
    'disney-vision': {
        'htmlElement': '&#57991;',
        'hexValue': 'e287',
        'name': 'disney-vision'
    },
    'dvd-cd': {
        'htmlElement': '&#57992;',
        'hexValue': 'e288',
        'name': 'dvd-cd'
    },
    'shdr-photopass': {
        'htmlElement': '&#57993;',
        'hexValue': 'e289',
        'name': 'shdr-photopass'
    },
    'order-dvd': {
        'htmlElement': '&#57995;',
        'hexValue': 'e28b',
        'name': 'order-dvd'
    },
    'presented-in-3d': {
        'htmlElement': '&#57996;',
        'hexValue': 'e28c',
        'name': 'presented-in-3d'
    },
    'guest-photos': {
        'htmlElement': '&#57997;',
        'hexValue': 'e28d',
        'name': 'guest-photos'
    },
    'on-demand': {
        'htmlElement': '&#57998;',
        'hexValue': 'e28e',
        'name': 'on-demand'
    },
    'live-stream': {
        'htmlElement': '&#57999;',
        'hexValue': 'e28f',
        'name': 'live-stream'
    },
    'photopass-lenses': {
        'htmlElement': '&#58000;',
        'hexValue': 'e290',
        'name': 'photopass-lenses'
    },
    'monorail': {
        'htmlElement': '&#58048;',
        'hexValue': 'e2c0',
        'name': 'monorail'
    },
    'bus': {
        'htmlElement': '&#58049;',
        'hexValue': 'e2c1',
        'name': 'bus'
    },
    'car': {
        'htmlElement': '&#58050;',
        'hexValue': 'e2c2',
        'name': 'car'
    },
    'boat': {
        'htmlElement': '&#58051;',
        'hexValue': 'e2c3',
        'name': 'boat'
    },
    'taxi': {
        'htmlElement': '&#58052;',
        'hexValue': 'e2c4',
        'name': 'taxi'
    },
    'flight': {
        'htmlElement': '&#58053;',
        'hexValue': 'e2c5',
        'name': 'flight'
    },
    'return-flight': {
        'htmlElement': '&#58054;',
        'hexValue': 'e2c6',
        'name': 'return-flight'
    },
    'walking': {
        'htmlElement': '&#58055;',
        'hexValue': 'e2c7',
        'name': 'walking'
    },
    'free-shuttle': {
        'htmlElement': '&#58056;',
        'hexValue': 'e2c8',
        'name': 'free-shuttle'
    },
    'hkdl-mtr-disney-resort-line': {
        'htmlElement': '&#58062;',
        'hexValue': 'e2ce',
        'name': 'hkdl-mtr-disney-resort-line'
    },
    'bus-schedule': {
        'htmlElement': '&#58063;',
        'hexValue': 'e2cf',
        'name': 'bus-schedule'
    },
    'icon_minnie-vans-ride-share': {
        'htmlElement': '&#58064;',
        'hexValue': 'e2d0',
        'name': 'icon_minnie-vans-ride-share'
    },
    'transportation-multiple': {
        'htmlElement': '&#58065;',
        'hexValue': 'e2d1',
        'name': 'transportation-multiple'
    },
    'wdw-skylinero': {
        'htmlElement': '&#58066;',
        'hexValue': 'e2d2',
        'name': 'wdw-skylinero'
    },
    'car-locator': {
        'htmlElement': '&#58067;',
        'hexValue': 'e2d3',
        'name': 'car-locator'
    },
    'close-button': {
        'htmlElement': '&#58112;',
        'hexValue': 'e300',
        'name': 'close-button'
    },
    'add-button': {
        'htmlElement': '&#58113;',
        'hexValue': 'e301',
        'name': 'add-button'
    },
    'information': {
        'htmlElement': '&#58114;',
        'hexValue': 'e302',
        'name': 'information'
    },
    'share': {
        'htmlElement': '&#58115;',
        'hexValue': 'e303',
        'name': 'share'
    },
    'download': {
        'htmlElement': '&#58116;',
        'hexValue': 'e304',
        'name': 'download'
    },
    'show-filters': {
        'htmlElement': '&#58117;',
        'hexValue': 'e305',
        'name': 'show-filters'
    },
    'hide-filters': {
        'htmlElement': '&#58118;',
        'hexValue': 'e306',
        'name': 'hide-filters'
    },
    '3d-spin': {
        'htmlElement': '&#58119;',
        'hexValue': 'e307',
        'name': '3d-spin'
    },
    'media-play-button': {
        'htmlElement': '&#58120;',
        'hexValue': 'e308',
        'name': 'media-play-button'
    },
    'media-pause-button': {
        'htmlElement': '&#58121;',
        'hexValue': 'e309',
        'name': 'media-pause-button'
    },
    'pdf': {
        'htmlElement': '&#58122;',
        'hexValue': 'e30a',
        'name': 'pdf'
    },
    'duplicate-offer': {
        'htmlElement': '&#58123;',
        'hexValue': 'e30b',
        'name': 'duplicate-offer'
    },
    'next': {
        'htmlElement': '&#58124;',
        'hexValue': 'e30c',
        'name': 'next'
    },
    'previous': {
        'htmlElement': '&#58125;',
        'hexValue': 'e30d',
        'name': 'previous'
    },
    'print': {
        'htmlElement': '&#58127;',
        'hexValue': 'e30f',
        'name': 'print'
    },
    'search': {
        'htmlElement': '&#58128;',
        'hexValue': 'e310',
        'name': 'search'
    },
    'calendar-month': {
        'htmlElement': '&#58129;',
        'hexValue': 'e311',
        'name': 'calendar-month'
    },
    'calendar-day-view': {
        'htmlElement': '&#58130;',
        'hexValue': 'e312',
        'name': 'calendar-day-view'
    },
    'more': {
        'htmlElement': '&#58131;',
        'hexValue': 'e313',
        'name': 'more'
    },
    'menu-global-nav': {
        'htmlElement': '&#58132;',
        'hexValue': 'e314',
        'name': 'menu-global-nav'
    },
    'menus-button': {
        'htmlElement': '&#58133;',
        'hexValue': 'e315',
        'name': 'menus-button'
    },
    'norgie-opened': {
        'htmlElement': '&#58134;',
        'hexValue': 'e316',
        'name': 'norgie-opened'
    },
    'upload': {
        'htmlElement': '&#58135;',
        'hexValue': 'e317',
        'name': 'upload'
    },
    'lists': {
        'htmlElement': '&#58136;',
        'hexValue': 'e318',
        'name': 'lists'
    },
    'filter': {
        'htmlElement': '&#58137;',
        'hexValue': 'e319',
        'name': 'filter'
    },
    'scan-barcode': {
        'htmlElement': '&#58138;',
        'hexValue': 'e31a',
        'name': 'scan-barcode'
    },
    'keyboard-entry': {
        'htmlElement': '&#58139;',
        'hexValue': 'e31b',
        'name': 'keyboard-entry'
    },
    'download-mobile-app': {
        'htmlElement': '&#58140;',
        'hexValue': 'e31c',
        'name': 'download-mobile-app'
    },
    'view-gallery': {
        'htmlElement': '&#58141;',
        'hexValue': 'e31d',
        'name': 'view-gallery'
    },
    'see-info-page-button': {
        'htmlElement': '&#58142;',
        'hexValue': 'e31e',
        'name': 'see-info-page-button'
    },
    'media-gallery-photo-button': {
        'htmlElement': '&#58143;',
        'hexValue': 'e31f',
        'name': 'media-gallery-photo-button'
    },
    'media-gallery-video-button': {
        'htmlElement': '&#58144;',
        'hexValue': 'e320',
        'name': 'media-gallery-video-button'
    },
    'refresh': {
        'htmlElement': '&#58145;',
        'hexValue': 'e321',
        'name': 'refresh'
    },
    'compose-message': {
        'htmlElement': '&#58146;',
        'hexValue': 'e322',
        'name': 'compose-message'
    },
    'add-contact': {
        'htmlElement': '&#58147;',
        'hexValue': 'e323',
        'name': 'add-contact'
    },
    'contact-list': {
        'htmlElement': '&#58148;',
        'hexValue': 'e324',
        'name': 'contact-list'
    },
    'norgie-up': {
        'htmlElement': '&#58149;',
        'hexValue': 'e325',
        'name': 'norgie-up'
    },
    'norgie-left': {
        'htmlElement': '&#58150;',
        'hexValue': 'e326',
        'name': 'norgie-left'
    },
    'mobile-app': {
        'htmlElement': '&#58151;',
        'hexValue': 'e327',
        'name': 'mobile-app'
    },
    'home': {
        'htmlElement': '&#58152;',
        'hexValue': 'e328',
        'name': 'home'
    },
    'return-arrow': {
        'htmlElement': '&#58153;',
        'hexValue': 'e329',
        'name': 'return-arrow'
    },
    'share-2': {
        'htmlElement': '&#58154;',
        'hexValue': 'e32a',
        'name': 'share-2'
    },
    'delete-button': {
        'htmlElement': '&#58155;',
        'hexValue': 'e32b',
        'name': 'delete-button'
    },
    'fast-forward-button': {
        'htmlElement': '&#58156;',
        'hexValue': 'e32c',
        'name': 'fast-forward-button'
    },
    'rewind-button': {
        'htmlElement': '&#58157;',
        'hexValue': 'e32d',
        'name': 'rewind-button'
    },
    'pulldown': {
        'htmlElement': '&#58158;',
        'hexValue': 'e32e',
        'name': 'pulldown'
    },
    'increase-button': {
        'htmlElement': '&#58159;',
        'hexValue': 'e32f',
        'name': 'increase-button'
    },
    'decrease-button': {
        'htmlElement': '&#58160;',
        'hexValue': 'e330',
        'name': 'decrease-button'
    },
    'flip-turn-over': {
        'htmlElement': '&#58161;',
        'hexValue': 'e331',
        'name': 'flip-turn-over'
    },
    'previous-2': {
        'htmlElement': '&#58162;',
        'hexValue': 'e332',
        'name': 'previous-2'
    },
    'next-2': {
        'htmlElement': '&#58163;',
        'hexValue': 'e333',
        'name': 'next-2'
    },
    'arrow-back': {
        'htmlElement': '&#58164;',
        'hexValue': 'e334',
        'name': 'arrow-back'
    },
    'arrow-up': {
        'htmlElement': '&#58165;',
        'hexValue': 'e335',
        'name': 'arrow-up'
    },
    'arrow-down': {
        'htmlElement': '&#58166;',
        'hexValue': 'e336',
        'name': 'arrow-down'
    },
    'expand-gallery': {
        'htmlElement': '&#58167;',
        'hexValue': 'e337',
        'name': 'expand-gallery'
    },
    '360-video': {
        'htmlElement': '&#58168;',
        'hexValue': 'e338',
        'name': '360-video'
    },
    'volume-control': {
        'htmlElement': '&#58169;',
        'hexValue': 'e339',
        'name': 'volume-control'
    },
    'app-card-info': {
        'htmlElement': '&#58170;',
        'hexValue': 'e33a',
        'name': 'app-card-info'
    },
    'app-card-flip': {
        'htmlElement': '&#58171;',
        'hexValue': 'e33b',
        'name': 'app-card-flip'
    },
    'icon_close-reversed': {
        'htmlElement': '&#58174;',
        'hexValue': 'e33e',
        'name': 'icon_close-reversed'
    },
    'icon_expand-show-more': {
        'htmlElement': '&#58175;',
        'hexValue': 'e33f',
        'name': 'icon_expand-show-more'
    },
    'wheelchair-accessibility': {
        'htmlElement': '&#58176;',
        'hexValue': 'e340',
        'name': 'wheelchair-accessibility'
    },
    'transfer-from-wheelchair': {
        'htmlElement': '&#58177;',
        'hexValue': 'e341',
        'name': 'transfer-from-wheelchair'
    },
    'transfer-to-wheelchair': {
        'htmlElement': '&#58178;',
        'hexValue': 'e342',
        'name': 'transfer-to-wheelchair'
    },
    'transfer-to-wheelchair-then-ride': {
        'htmlElement': '&#58179;',
        'hexValue': 'e343',
        'name': 'transfer-to-wheelchair-then-ride'
    },
    'ambulatory': {
        'htmlElement': '&#58180;',
        'hexValue': 'e344',
        'name': 'ambulatory'
    },
    'visual-impairment': {
        'htmlElement': '&#58181;',
        'hexValue': 'e345',
        'name': 'visual-impairment'
    },
    'hearing-impairment': {
        'htmlElement': '&#58182;',
        'hexValue': 'e346',
        'name': 'hearing-impairment'
    },
    'sign-language': {
        'htmlElement': '&#58183;',
        'hexValue': 'e347',
        'name': 'sign-language'
    },
    'audio-description': {
        'htmlElement': '&#58184;',
        'hexValue': 'e348',
        'name': 'audio-description'
    },
    'handheld-captioning': {
        'htmlElement': '&#58185;',
        'hexValue': 'e349',
        'name': 'handheld-captioning'
    },
    'reflective-captioning': {
        'htmlElement': '&#58186;',
        'hexValue': 'e34a',
        'name': 'reflective-captioning'
    },
    'closed-captioning': {
        'htmlElement': '&#58187;',
        'hexValue': 'e34b',
        'name': 'closed-captioning'
    },
    'physicial-consideration-red-yellow': {
        'htmlElement': '&#58188;',
        'hexValue': 'e34c',
        'name': 'physicial-consideration-red-yellow'
    },
    'scary-consideration-yellow': {
        'htmlElement': '&#58189;',
        'hexValue': 'e34d',
        'name': 'scary-consideration-yellow'
    },
    'dlp-mobility-disabilities': {
        'htmlElement': '&#58190;',
        'hexValue': 'e34e',
        'name': 'dlp-mobility-disabilities'
    },
    'dlp-hearing-disabilities': {
        'htmlElement': '&#58191;',
        'hexValue': 'e34f',
        'name': 'dlp-hearing-disabilities'
    },
    'dlp-visual-disabilities': {
        'htmlElement': '&#58192;',
        'hexValue': 'e350',
        'name': 'dlp-visual-disabilities'
    },
    'photosensitive-epilepsey': {
        'htmlElement': '&#58193;',
        'hexValue': 'e351',
        'name': 'photosensitive-epilepsey'
    },
    'dlp-mental-cognitive-disabilities': {
        'htmlElement': '&#58194;',
        'hexValue': 'e352',
        'name': 'dlp-mental-cognitive-disabilities'
    },
    'suitable-for-amputees': {
        'htmlElement': '&#58195;',
        'hexValue': 'e353',
        'name': 'suitable-for-amputees'
    },
    'suitable-for-difficulty-standing': {
        'htmlElement': '&#58196;',
        'hexValue': 'e354',
        'name': 'suitable-for-difficulty-standing'
    },
    'capable-of-climbing-steps': {
        'htmlElement': '&#58197;',
        'hexValue': 'e355',
        'name': 'capable-of-climbing-steps'
    },
    'disabilities-services': {
        'htmlElement': '&#58198;',
        'hexValue': 'e356',
        'name': 'disabilities-services'
    },
    'disabilities-guide-book': {
        'htmlElement': '&#58199;',
        'hexValue': 'e357',
        'name': 'disabilities-guide-book'
    },
    'wheelchari-rental': {
        'htmlElement': '&#58200;',
        'hexValue': 'e358',
        'name': 'wheelchari-rental'
    },
    'disabilities-designated-viewing': {
        'htmlElement': '&#58201;',
        'hexValue': 'e359',
        'name': 'disabilities-designated-viewing'
    },
    'may-remain-in-wheelchair': {
        'htmlElement': '&#58202;',
        'hexValue': 'e35a',
        'name': 'may-remain-in-wheelchair'
    },
    'must-be-ambulatory-2': {
        'htmlElement': '&#58203;',
        'hexValue': 'e35b',
        'name': 'must-be-ambulatory-2'
    },
    'suitable-for-blind': {
        'htmlElement': '&#58204;',
        'hexValue': 'e35c',
        'name': 'suitable-for-blind'
    },
    'suitable-for-autism': {
        'htmlElement': '&#58205;',
        'hexValue': 'e35d',
        'name': 'suitable-for-autism'
    },
    'sign-language-2': {
        'htmlElement': '&#58206;',
        'hexValue': 'e35e',
        'name': 'sign-language-2'
    },
    'tactile-maps': {
        'htmlElement': '&#58207;',
        'hexValue': 'e35f',
        'name': 'tactile-maps'
    },
    'braille-guide-books': {
        'htmlElement': '&#58208;',
        'hexValue': 'e360',
        'name': 'braille-guide-books'
    },
    'suitable-for-learning-disabilities': {
        'htmlElement': '&#58209;',
        'hexValue': 'e361',
        'name': 'suitable-for-learning-disabilities'
    },
    'induction-loop': {
        'htmlElement': '&#58210;',
        'hexValue': 'e362',
        'name': 'induction-loop'
    },
    'dimly-lit-space': {
        'htmlElement': '&#58211;',
        'hexValue': 'e363',
        'name': 'dimly-lit-space'
    },
    'light-rain-indoors': {
        'htmlElement': '&#58212;',
        'hexValue': 'e364',
        'name': 'light-rain-indoors'
    },
    'show-contains-bubble': {
        'htmlElement': '&#58213;',
        'hexValue': 'e365',
        'name': 'show-contains-bubble'
    },
    'hdd-illness': {
        'htmlElement': '&#58214;',
        'hexValue': 'e366',
        'name': 'hdd-illness'
    },
    'open-captioning': {
        'htmlElement': '&#58215;',
        'hexValue': 'e367',
        'name': 'open-captioning'
    },
    'magnetic-fields': {
        'htmlElement': '&#58216;',
        'hexValue': 'e368',
        'name': 'magnetic-fields'
    },
    'open-captioning-square': {
        'htmlElement': '&#58217;',
        'hexValue': 'e369',
        'name': 'open-captioning-square'
    },
    'gifts': {
        'htmlElement': '&#58240;',
        'hexValue': 'e380',
        'name': 'gifts'
    },
    'character-calls': {
        'htmlElement': '&#58241;',
        'hexValue': 'e381',
        'name': 'character-calls'
    },
    'performaing-arts': {
        'htmlElement': '&#58242;',
        'hexValue': 'e382',
        'name': 'performaing-arts'
    },
    'education': {
        'htmlElement': '&#58243;',
        'hexValue': 'e383',
        'name': 'education'
    },
    'lock': {
        'htmlElement': '&#58244;',
        'hexValue': 'e384',
        'name': 'lock'
    },
    'facebook': {
        'htmlElement': '&#58245;',
        'hexValue': 'e385',
        'name': 'facebook'
    },
    'twitter': {
        'htmlElement': '&#58246;',
        'hexValue': 'e386',
        'name': 'twitter'
    },
    'youtube': {
        'htmlElement': '&#58247;',
        'hexValue': 'e387',
        'name': 'youtube'
    },
    'instagram': {
        'htmlElement': '&#58248;',
        'hexValue': 'e388',
        'name': 'instagram'
    },
    'pinterest': {
        'htmlElement': '&#58249;',
        'hexValue': 'e389',
        'name': 'pinterest'
    },
    'google-plus': {
        'htmlElement': '&#58250;',
        'hexValue': 'e38a',
        'name': 'google-plus'
    },
    'disney-cast': {
        'htmlElement': '&#58251;',
        'hexValue': 'e38b',
        'name': 'disney-cast'
    },
    'disney-response': {
        'htmlElement': '&#58252;',
        'hexValue': 'e38c',
        'name': 'disney-response'
    },
    'thrill-seekers': {
        'htmlElement': '&#58254;',
        'hexValue': 'e38e',
        'name': 'thrill-seekers'
    },
    'doll': {
        'htmlElement': '&#58256;',
        'hexValue': 'e390',
        'name': 'doll'
    },
    'front-desk': {
        'htmlElement': '&#58257;',
        'hexValue': 'e391',
        'name': 'front-desk'
    },
    'movies': {
        'htmlElement': '&#58258;',
        'hexValue': 'e392',
        'name': 'movies'
    },
    'unlocked': {
        'htmlElement': '&#58259;',
        'hexValue': 'e393',
        'name': 'unlocked'
    },
    'tumblr': {
        'htmlElement': '&#58260;',
        'hexValue': 'e394',
        'name': 'tumblr'
    },
    'services': {
        'htmlElement': '&#58261;',
        'hexValue': 'e395',
        'name': 'services'
    },
    'old-photopass-plus': {
        'htmlElement': '&#58262;',
        'hexValue': 'e396',
        'name': 'old-photopass-plus'
    },
    'hkdl-sina-weibo-social': {
        'htmlElement': '&#58263;',
        'hexValue': 'e397',
        'name': 'hkdl-sina-weibo-social'
    },
    'hkdl-baidu-tieba-social': {
        'htmlElement': '&#58264;',
        'hexValue': 'e398',
        'name': 'hkdl-baidu-tieba-social'
    },
    'hkdl-webchat-social': {
        'htmlElement': '&#58265;',
        'hexValue': 'e399',
        'name': 'hkdl-webchat-social'
    },
    'disney-wild-about-safety': {
        'htmlElement': '&#58266;',
        'hexValue': 'e39a',
        'name': 'disney-wild-about-safety'
    },
    'romantic-celebration': {
        'htmlElement': '&#58267;',
        'hexValue': 'e39b',
        'name': 'romantic-celebration'
    },
    'facebook-icon-alone': {
        'htmlElement': '&#58268;',
        'hexValue': 'e39c',
        'name': 'facebook-icon-alone'
    },
    'twitter-icon-alone': {
        'htmlElement': '&#58269;',
        'hexValue': 'e39d',
        'name': 'twitter-icon-alone'
    },
    'snapchat': {
        'htmlElement': '&#58270;',
        'hexValue': 'e39e',
        'name': 'snapchat'
    },
    'photopass-attraction-id': {
        'htmlElement': '&#58271;',
        'hexValue': 'e39f',
        'name': 'photopass-attraction-id'
    },
    'achievement': {
        'htmlElement': '&#58272;',
        'hexValue': 'e3a0',
        'name': 'achievement'
    },
    'icon_apple-music': {
        'htmlElement': '&#58273;',
        'hexValue': 'e3a1',
        'name': 'icon_apple-music'
    },
    'apple-music-alternate': {
        'htmlElement': '&#58274;',
        'hexValue': 'e3a2',
        'name': 'apple-music-alternate'
    },
    'watches-time': {
        'htmlElement': '&#58275;',
        'hexValue': 'e3a3',
        'name': 'watches-time'
    },
    'magic-coming-soon': {
        'htmlElement': '&#58276;',
        'hexValue': 'e3a4',
        'name': 'magic-coming-soon'
    },
    'winter': {
        'htmlElement': '&#58340;',
        'hexValue': 'e3e4',
        'name': 'winter'
    },
    'spring': {
        'htmlElement': '&#58341;',
        'hexValue': 'e3e5',
        'name': 'spring'
    },
    'summer': {
        'htmlElement': '&#58342;',
        'hexValue': 'e3e6',
        'name': 'summer'
    },
    'autumn': {
        'htmlElement': '&#58343;',
        'hexValue': 'e3e7',
        'name': 'autumn'
    },
    'pack-for-weather': {
        'htmlElement': '&#58344;',
        'hexValue': 'e3e8',
        'name': 'pack-for-weather'
    },
    'single-rider': {
        'htmlElement': '&#58432;',
        'hexValue': 'e440',
        'name': 'single-rider'
    },
    'atm-china': {
        'htmlElement': '&#58433;',
        'hexValue': 'e441',
        'name': 'atm-china'
    },
    'wayfinding-slight-left': {
        'htmlElement': '&#58434;',
        'hexValue': 'e442',
        'name': 'wayfinding-slight-left'
    },
    'wayfinding-slight-right': {
        'htmlElement': '&#58435;',
        'hexValue': 'e443',
        'name': 'wayfinding-slight-right'
    },
    'wayfinding-turn-left': {
        'htmlElement': '&#58436;',
        'hexValue': 'e444',
        'name': 'wayfinding-turn-left'
    },
    'wayfinding-turn-right': {
        'htmlElement': '&#58437;',
        'hexValue': 'e445',
        'name': 'wayfinding-turn-right'
    },
    'wayfinding-hard-left': {
        'htmlElement': '&#58438;',
        'hexValue': 'e446',
        'name': 'wayfinding-hard-left'
    },
    'wayfinding-hard-right': {
        'htmlElement': '&#58439;',
        'hexValue': 'e447',
        'name': 'wayfinding-hard-right'
    },
    'wayfinding-left': {
        'htmlElement': '&#58440;',
        'hexValue': 'e448',
        'name': 'wayfinding-left'
    },
    'wayfinding-right': {
        'htmlElement': '&#58441;',
        'hexValue': 'e449',
        'name': 'wayfinding-right'
    },
    'wayfinding-forward': {
        'htmlElement': '&#58442;',
        'hexValue': 'e44a',
        'name': 'wayfinding-forward'
    },
    'wayfinding-back': {
        'htmlElement': '&#58443;',
        'hexValue': 'e44b',
        'name': 'wayfinding-back'
    },
    'wayfinding-forward-back': {
        'htmlElement': '&#58444;',
        'hexValue': 'e44c',
        'name': 'wayfinding-forward-back'
    },
    'wayfinding-right-left': {
        'htmlElement': '&#58445;',
        'hexValue': 'e44d',
        'name': 'wayfinding-right-left'
    },
    'wayfinding-around-slight-left': {
        'htmlElement': '&#58446;',
        'hexValue': 'e44e',
        'name': 'wayfinding-around-slight-left'
    },
    'wayfinding-around-slight-right': {
        'htmlElement': '&#58447;',
        'hexValue': 'e44f',
        'name': 'wayfinding-around-slight-right'
    },
    'wayfinding-around-turn-left': {
        'htmlElement': '&#58448;',
        'hexValue': 'e450',
        'name': 'wayfinding-around-turn-left'
    },
    'wayfinding-around-turn-right': {
        'htmlElement': '&#58449;',
        'hexValue': 'e451',
        'name': 'wayfinding-around-turn-right'
    },
    'wayfinding-around-hard-left': {
        'htmlElement': '&#58450;',
        'hexValue': 'e452',
        'name': 'wayfinding-around-hard-left'
    },
    'wayfinding-around-hard-right': {
        'htmlElement': '&#58451;',
        'hexValue': 'e453',
        'name': 'wayfinding-around-hard-right'
    },
    'wayfinding-around-left': {
        'htmlElement': '&#58452;',
        'hexValue': 'e454',
        'name': 'wayfinding-around-left'
    },
    'wayfinding-around-right': {
        'htmlElement': '&#58453;',
        'hexValue': 'e455',
        'name': 'wayfinding-around-right'
    },
    'wayfinding-around-forward-left': {
        'htmlElement': '&#58454;',
        'hexValue': 'e456',
        'name': 'wayfinding-around-forward-left'
    },
    'wayfinding-around-forward-right': {
        'htmlElement': '&#58455;',
        'hexValue': 'e457',
        'name': 'wayfinding-around-forward-right'
    },
    'wayfinding-u-turn-right': {
        'htmlElement': '&#58456;',
        'hexValue': 'e458',
        'name': 'wayfinding-u-turn-right'
    },
    'wayfinding-u-turn-left': {
        'htmlElement': '&#58457;',
        'hexValue': 'e459',
        'name': 'wayfinding-u-turn-left'
    },
    'wayfinding-ramp-left': {
        'htmlElement': '&#58458;',
        'hexValue': 'e45a',
        'name': 'wayfinding-ramp-left'
    },
    'wayfinding-ramp-right': {
        'htmlElement': '&#58459;',
        'hexValue': 'e45b',
        'name': 'wayfinding-ramp-right'
    },
    'wayfinding-fork-left': {
        'htmlElement': '&#58460;',
        'hexValue': 'e45c',
        'name': 'wayfinding-fork-left'
    },
    'wayfinding-fork-right': {
        'htmlElement': '&#58461;',
        'hexValue': 'e45d',
        'name': 'wayfinding-fork-right'
    },
    'wayfinding-around-about-left': {
        'htmlElement': '&#58462;',
        'hexValue': 'e45e',
        'name': 'wayfinding-around-about-left'
    },
    'wayfinding-around-about-right': {
        'htmlElement': '&#58463;',
        'hexValue': 'e45f',
        'name': 'wayfinding-around-about-right'
    },
    'wayfinding-merge': {
        'htmlElement': '&#58464;',
        'hexValue': 'e460',
        'name': 'wayfinding-merge'
    },
    'cribs-and-playpens': {
        'htmlElement': '&#58465;',
        'hexValue': 'e461',
        'name': 'cribs-and-playpens'
    },
    'baby-care-center2': {
        'htmlElement': '&#58466;',
        'hexValue': 'e462',
        'name': 'baby-care-center2'
    },
    'epi-pen': {
        'htmlElement': '&#58467;',
        'hexValue': 'e463',
        'name': 'epi-pen'
    },
    'mobile-charging-station': {
        'htmlElement': '&#58468;',
        'hexValue': 'e464',
        'name': 'mobile-charging-station'
    },
    'security-info': {
        'htmlElement': '&#58469;',
        'hexValue': 'e465',
        'name': 'security-info'
    },
    'refrigerator-available': {
        'htmlElement': '&#58470;',
        'hexValue': 'e466',
        'name': 'refrigerator-available'
    },
    'repellant-zika': {
        'htmlElement': '&#58471;',
        'hexValue': 'e467',
        'name': 'repellant-zika'
    },
    'hurricane-information': {
        'htmlElement': '&#58472;',
        'hexValue': 'e468',
        'name': 'hurricane-information'
    },
    'recenter-map': {
        'htmlElement': '&#58474;',
        'hexValue': 'e46a',
        'name': 'recenter-map'
    },
    'transportation-parking-info': {
        'htmlElement': '&#58477;',
        'hexValue': 'e46d',
        'name': 'transportation-parking-info'
    },
    'resort-automatic-check-out': {
        'htmlElement': '&#58478;',
        'hexValue': 'e46e',
        'name': 'resort-automatic-check-out'
    },
    'service-your-way': {
        'htmlElement': '&#58479;',
        'hexValue': 'e46f',
        'name': 'service-your-way'
    },
    'direct-to-room': {
        'htmlElement': '&#58480;',
        'hexValue': 'e470',
        'name': 'direct-to-room'
    },
    'premium-service-shdr': {
        'htmlElement': '&#58481;',
        'hexValue': 'e471',
        'name': 'premium-service-shdr'
    },
    'virtual-queue': {
        'htmlElement': '&#58482;',
        'hexValue': 'e472',
        'name': 'virtual-queue'
    },
    'energy-monster': {
        'htmlElement': '&#58483;',
        'hexValue': 'e473',
        'name': 'energy-monster'
    },
    'elevators': {
        'htmlElement': '&#58485;',
        'hexValue': 'e475',
        'name': 'elevators'
    },
    'salon': {
        'htmlElement': '&#58486;',
        'hexValue': 'e476',
        'name': 'salon'
    },
    'clothing': {
        'htmlElement': '&#58496;',
        'hexValue': 'e480',
        'name': 'clothing'
    },
    'accessories': {
        'htmlElement': '&#58497;',
        'hexValue': 'e481',
        'name': 'accessories'
    },
    'home-office': {
        'htmlElement': '&#58498;',
        'hexValue': 'e482',
        'name': 'home-office'
    },
    'toys-games': {
        'htmlElement': '&#58499;',
        'hexValue': 'e483',
        'name': 'toys-games'
    },
    'art-collectibles': {
        'htmlElement': '&#58500;',
        'hexValue': 'e484',
        'name': 'art-collectibles'
    },
    'entretainment': {
        'htmlElement': '&#58501;',
        'hexValue': 'e485',
        'name': 'entretainment'
    },
    'health-beauty': {
        'htmlElement': '&#58502;',
        'hexValue': 'e486',
        'name': 'health-beauty'
    },
    'sweets': {
        'htmlElement': '&#58503;',
        'hexValue': 'e487',
        'name': 'sweets'
    },
    'icon_play-logo': {
        'htmlElement': '&#58560;',
        'hexValue': 'e4c0',
        'name': 'icon_play-logo'
    },
    'icon_rocket-race-game': {
        'htmlElement': '&#58561;',
        'hexValue': 'e4c1',
        'name': 'icon_rocket-race-game'
    },
    'icon_peter-pan-s-flight-game': {
        'htmlElement': '&#58562;',
        'hexValue': 'e4c2',
        'name': 'icon_peter-pan-s-flight-game'
    },
    'icon_toy-story-mania-game': {
        'htmlElement': '&#58563;',
        'hexValue': 'e4c3',
        'name': 'icon_toy-story-mania-game'
    },
    'icon_trivia-games': {
        'htmlElement': '&#58564;',
        'hexValue': 'e4c4',
        'name': 'icon_trivia-games'
    },
    'icon_esmeralda-quest': {
        'htmlElement': '&#58565;',
        'hexValue': 'e4c5',
        'name': 'icon_esmeralda-quest'
    },
    'icon_fortune-red-quest': {
        'htmlElement': '&#58566;',
        'hexValue': 'e4c6',
        'name': 'icon_fortune-red-quest'
    },
    'icon_temple-of-mara-decoder': {
        'htmlElement': '&#58567;',
        'hexValue': 'e4c7',
        'name': 'icon_temple-of-mara-decoder'
    },
    'icon_conservation-fund-quest': {
        'htmlElement': '&#58568;',
        'hexValue': 'e4c8',
        'name': 'icon_conservation-fund-quest'
    },
    'icon_soarin-challenge': {
        'htmlElement': '&#58569;',
        'hexValue': 'e4c9',
        'name': 'icon_soarin-challenge'
    },
    'icon_agent-p-challenge': {
        'htmlElement': '&#58570;',
        'hexValue': 'e4ca',
        'name': 'icon_agent-p-challenge'
    },
    'icon_fun-wheel-challenge': {
        'htmlElement': '&#58571;',
        'hexValue': 'e4cb',
        'name': 'icon_fun-wheel-challenge'
    },
    'icon_andy-s-game-board-blast': {
        'htmlElement': '&#58572;',
        'hexValue': 'e4cc',
        'name': 'icon_andy-s-game-board-blast'
    },
    'art-of-animation': {
        'htmlElement': '&#58573;',
        'hexValue': 'e4cd',
        'name': 'art-of-animation'
    },
    'flight-of-passage': {
        'htmlElement': '&#58574;',
        'hexValue': 'e4ce',
        'name': 'flight-of-passage'
    },
    'dlr-magic-mural': {
        'htmlElement': '&#58575;',
        'hexValue': 'e4cf',
        'name': 'dlr-magic-mural'
    },
    'splash-mountain': {
        'htmlElement': '&#58576;',
        'hexValue': 'e4d0',
        'name': 'splash-mountain'
    },
    'kidcot-challenge': {
        'htmlElement': '&#58577;',
        'hexValue': 'e4d1',
        'name': 'kidcot-challenge'
    },
    'swge-millenium-falcon': {
        'htmlElement': '&#58578;',
        'hexValue': 'e4d2',
        'name': 'swge-millenium-falcon'
    },
    'swge-star-destroyer': {
        'htmlElement': '&#58579;',
        'hexValue': 'e4d3',
        'name': 'swge-star-destroyer'
    },
    'swge-bso-logo': {
        'htmlElement': '&#58580;',
        'hexValue': 'e4d4',
        'name': 'swge-bso-logo'
    },
    'icon_add-stepper': {
        'htmlElement': '&#58624;',
        'hexValue': 'e500',
        'name': 'icon_add-stepper'
    },
    'icon_reduce-stepper': {
        'htmlElement': '&#58625;',
        'hexValue': 'e501',
        'name': 'icon_reduce-stepper'
    },
    'hide-collapse': {
        'htmlElement': '&#58626;',
        'hexValue': 'e502',
        'name': 'hide-collapse'
    },
    'mute-volume': {
        'htmlElement': '&#58627;',
        'hexValue': 'e503',
        'name': 'mute-volume'
    },
    'unmute-volume': {
        'htmlElement': '&#58628;',
        'hexValue': 'e504',
        'name': 'unmute-volume'
    },
    'filter-items': {
        'htmlElement': '&#58629;',
        'hexValue': 'e505',
        'name': 'filter-items'
    },
    'forward-thin': {
        'htmlElement': '&#58630;',
        'hexValue': 'e506',
        'name': 'forward-thin'
    },
    'back-thin': {
        'htmlElement': '&#58631;',
        'hexValue': 'e507',
        'name': 'back-thin'
    },
    'fast-fwd-thin': {
        'htmlElement': '&#58632;',
        'hexValue': 'e508',
        'name': 'fast-fwd-thin'
    },
    'rewind-thin': {
        'htmlElement': '&#58633;',
        'hexValue': 'e509',
        'name': 'rewind-thin'
    },
    'download-thin': {
        'htmlElement': '&#58634;',
        'hexValue': 'e50a',
        'name': 'download-thin'
    },
    'facial-opt-in-off': {
        'htmlElement': '&#58635;',
        'hexValue': 'e50b',
        'name': 'facial-opt-in-off'
    },
    'facial-opt-in-on': {
        'htmlElement': '&#58636;',
        'hexValue': 'e50c',
        'name': 'facial-opt-in-on'
    },
    'bluetooth': {
        'htmlElement': '&#58648;',
        'hexValue': 'e518',
        'name': 'bluetooth'
    },
    'media-play': {
        'htmlElement': '&#58652;',
        'hexValue': 'e51c',
        'name': 'media-play'
    },
    'media-pause': {
        'htmlElement': '&#58653;',
        'hexValue': 'e51d',
        'name': 'media-pause'
    },
    'media-unmute': {
        'htmlElement': '&#58654;',
        'hexValue': 'e51e',
        'name': 'media-unmute'
    },
    'media-mute': {
        'htmlElement': '&#58655;',
        'hexValue': 'e51f',
        'name': 'media-mute'
    },
    'media-caption-on': {
        'htmlElement': '&#58656;',
        'hexValue': 'e520',
        'name': 'media-caption-on'
    },
    'media-caption-off': {
        'htmlElement': '&#58657;',
        'hexValue': 'e521',
        'name': 'media-caption-off'
    },
    'media-back-10s': {
        'htmlElement': '&#58658;',
        'hexValue': 'e522',
        'name': 'media-back-10s'
    },
    'media-fwd-10s': {
        'htmlElement': '&#58659;',
        'hexValue': 'e523',
        'name': 'media-fwd-10s'
    },
    'media-back': {
        'htmlElement': '&#58660;',
        'hexValue': 'e524',
        'name': 'media-back'
    },
    'media-fwd': {
        'htmlElement': '&#58661;',
        'hexValue': 'e525',
        'name': 'media-fwd'
    },
    'rotate-device': {
        'htmlElement': '&#58662;',
        'hexValue': 'e526',
        'name': 'rotate-device'
    },
    'norgie-closed': {
        'htmlElement': '&#58676;',
        'hexValue': 'e534',
        'name': 'norgie-closed'
    },
    'fastpass-order-shopping-bag-plus': {
        'htmlElement': '&#58688;',
        'hexValue': 'e540',
        'name': 'fastpass-order-shopping-bag-plus'
    },
    'fastpass-plus-bundle-compare': {
        'htmlElement': '&#58689;',
        'hexValue': 'e541',
        'name': 'fastpass-plus-bundle-compare'
    },
    'fastpass-order-shopping-bag': {
        'htmlElement': '&#58690;',
        'hexValue': 'e542',
        'name': 'fastpass-order-shopping-bag'
    },
    'fastpass-bundle-compare': {
        'htmlElement': '&#58691;',
        'hexValue': 'e543',
        'name': 'fastpass-bundle-compare'
    },
    'shdr-ticket-annualpass': {
        'htmlElement': '&#58692;',
        'hexValue': 'e544',
        'name': 'shdr-ticket-annualpass'
    },
    'fastpass-plus-extras': {
        'htmlElement': '&#58693;',
        'hexValue': 'e545',
        'name': 'fastpass-plus-extras'
    },
    'fastpass-plus-collections': {
        'htmlElement': '&#58694;',
        'hexValue': 'e546',
        'name': 'fastpass-plus-collections'
    },
    'fastpass-plus-ultimate': {
        'htmlElement': '&#58695;',
        'hexValue': 'e547',
        'name': 'fastpass-plus-ultimate'
    },
    'fastpass-extras': {
        'htmlElement': '&#58696;',
        'hexValue': 'e548',
        'name': 'fastpass-extras'
    },
    'fastpass-collections': {
        'htmlElement': '&#58697;',
        'hexValue': 'e549',
        'name': 'fastpass-collections'
    },
    'fastpass-ultimate': {
        'htmlElement': '&#58698;',
        'hexValue': 'e54a',
        'name': 'fastpass-ultimate'
    },
    'tickets-outlined': {
        'htmlElement': '&#58699;',
        'hexValue': 'e54b',
        'name': 'tickets-outlined'
    },
    'early-entry-pass': {
        'htmlElement': '&#58700;',
        'hexValue': 'e54c',
        'name': 'early-entry-pass'
    },
    'disney-card-member': {
        'htmlElement': '&#58701;',
        'hexValue': 'e54d',
        'name': 'disney-card-member'
    },
    'club-33-dlr-member': {
        'htmlElement': '&#58702;',
        'hexValue': 'e54e',
        'name': 'club-33-dlr-member'
    },
    'shdr-ap-activation': {
        'htmlElement': '&#58703;',
        'hexValue': 'e54f',
        'name': 'shdr-ap-activation'
    },
    'disney-maxpass-alternate': {
        'htmlElement': '&#58704;',
        'hexValue': 'e550',
        'name': 'disney-maxpass-alternate'
    },
    'ap-activation': {
        'htmlElement': '&#58706;',
        'hexValue': 'e552',
        'name': 'ap-activation'
    },
    'standby-pass': {
        'htmlElement': '&#58707;',
        'hexValue': 'e553',
        'name': 'standby-pass'
    },
    'early-park-entry-pass': {
        'htmlElement': '&#58708;',
        'hexValue': 'e554',
        'name': 'early-park-entry-pass'
    },
    'mobile-checkout': {
        'htmlElement': '&#58709;',
        'hexValue': 'e555',
        'name': 'mobile-checkout'
    },
    'disney-premier-access-global': {
        'htmlElement': '&#58710;',
        'hexValue': 'e556',
        'name': 'disney-premier-access-global'
    },
    'disney-premier-access-secondary': {
        'htmlElement': '&#58711;',
        'hexValue': 'e557',
        'name': 'disney-premier-access-secondary'
    },
    'disney-premier-access-new': {
        'htmlElement': '&#58712;',
        'hexValue': 'e558',
        'name': 'disney-premier-access-new'
    },
    'golden-oak-resident': {
        'htmlElement': '&#58713;',
        'hexValue': 'e559',
        'name': 'golden-oak-resident'
    },
    'disability-access-service': {
        'htmlElement': '&#58714;',
        'hexValue': 'e55a',
        'name': 'disability-access-service'
    },
    'lightning-lane': {
        'htmlElement': '&#58715;',
        'hexValue': 'e55b',
        'name': 'lightning-lane'
    },
    'disney-plus': {
        'htmlElement': '&#58716;',
        'hexValue': 'e55c',
        'name': 'disney-plus'
    },
    'enchant-key': {
        'htmlElement': '&#58717;',
        'hexValue': 'e55d',
        'name': 'enchant-key'
    },
    'dream-key': {
        'htmlElement': '&#58718;',
        'hexValue': 'e55e',
        'name': 'dream-key'
    },
    'imagine-key': {
        'htmlElement': '&#58719;',
        'hexValue': 'e55f',
        'name': 'imagine-key'
    },
    'believe-key': {
        'htmlElement': '&#58720;',
        'hexValue': 'e560',
        'name': 'believe-key'
    },
    'upgrade-annual-pass-wdw': {
        'htmlElement': '&#58721;',
        'hexValue': 'e561',
        'name': 'upgrade-annual-pass-wdw'
    },
    'upgrade-annual-pass-dlr': {
        'htmlElement': '&#58722;',
        'hexValue': 'e562',
        'name': 'upgrade-annual-pass-dlr'
    },
    'inspire-key': {
        'htmlElement': '&#58723;',
        'hexValue': 'e563',
        'name': 'inspire-key'
    },
    'dlr-magic-key-passholder': {
        'htmlElement': '&#58724;',
        'hexValue': 'e564',
        'name': 'dlr-magic-key-passholder'
    },
    'unused-character': {
        'htmlElement': '&#63742;',
        'hexValue': 'f8fe',
        'name': 'unused-character'
    }
};

// Overrides for bad names
WEB_FONT_VALUES['inspire-key'] = {
    'htmlElement': '&#58723;',
    'hexValue': 'e563',
    'name': 'magic-key-inspire'
};
WEB_FONT_VALUES['believe-key'] = {
    'htmlElement': '&#58720;',
    'hexValue': 'e560',
    'name': 'magic-key-believe'
};
WEB_FONT_VALUES['enchant-key'] = {
    'htmlElement': '&#58717;',
    'hexValue': 'e55d',
    'name': 'magic-key-enchant'
};
WEB_FONT_VALUES['imagine-key'] = {
    'htmlElement': '&#58719;',
    'hexValue': 'e55f',
    'name': 'magic-key-imagine'
};
