import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ConfigService } from '@finder/core/config.service';
import { CONFIG_CONSTANTS } from '@finder/shared/constants/app.constants';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { LoggerService } from '@wdpr/ra-angular-logger';

import isEmpty from 'lodash-es/isEmpty';
import { fromEvent, merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { FONT_ICON_DEFAULT, WEB_FONT_VALUES } from './font-icon.constants';

/**
 * Creates a font-icon. For semantic icons, please add extra
 * markup to provide guidance to guests with visual disabilities.
 *
 * @example <finder-font-icon name="all-activities" title="All Activities" size="m"></finder-font-icon>
 * @param name string The icon's name. These can be found at [WDW]{@link https://disneyworld.disney.go.com/debug/web-font/}
 * @param size string The size of the icon. Values: xxs|xs|s|m|l|xl. Leave this param empty to inherit the font-size property.
 * @param title string The title of the icon. Used to show a tooltip upon hovering the icon.
 * @param hexValue string The hexCharacter of the icon.
 *
 * Icons can be viewed here
 * https://cdn1.parksmedia.wdprapps.disney.com/media/layout/assets/icons/pep/index.html
 */

@Component({
    selector: 'finder-font-icon',
    templateUrl: './font-icon.component.html',
    styleUrls: ['./font-icon.component.scss']
})
export class FontIconComponent implements OnInit, OnChanges {
    @Input() public name = FONT_ICON_DEFAULT;
    @Input() public size = 's';
    @Input() public title: string;
    @Input() public svgSrc: string;
    @Input() public ariaHidden = true;
    @Input() public color: string;

    // Values that must be converted to work with SVG
    @Input() public hexValue: string;
    @Input() public htmlEntity = ''; // Needs to be initialized since it is HTML bound

    @ViewChild('fallback', { static: true }) fallBackImage: ElementRef;

    imgSrc;

    private COMPONENT_NAME = 'font-icon:';

    constructor(
        private configService: ConfigService,
        private elementRef: ElementRef,
        private winRef: WindowRef,
        private logger: LoggerService
    ) {}

    /* istanbul ignore next */
    get elementReference() {
        return this.elementRef.nativeElement;
    }

    get windowReference() {
        return this.winRef.nativeWindow;
    }

    ngOnInit() {
        this.setupIcon();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Ignore anything with firstChange in it
        const keys = Object.keys(changes);
        const firstChange = keys.find(key => changes[key].isFirstChange());
        if (firstChange) {
            return;
        }

        // Fish out the changes
        keys.forEach(key => {
            this[key] = changes[key].currentValue;
        });

        this.setupIcon();
    }

    private setupIcon() {
        // Defaulting Inputs
        this.title = isEmpty(this.title) ? null : this.title;

        // Sometimes name can come through as undefined.
        this.name = isEmpty(this.name) ? FONT_ICON_DEFAULT : this.name;

        // If we have a hexValue we need to map it back to a name: e.g. e200
        if (this.hexValue) {
            this.name = this.findValue('hexValue', this.hexValue) || this.name;
        }

        // If we have a htmlEntity value we need to find the name: e.g. &#58116;
        if (this.htmlEntity) {
            // We have to convert this to a value we can compare
            this.name = this.findValue('htmlElement', this.htmlEntity) || this.name;
        }

        // Set the color via css var
        if (this.color) {
            this.setCssVar('--font-icon-svg-background-color', this.color);
        }

        // Create the image
        this.setUpSvg();
    }

    private setUpSvg() {
        // Used to cancel both observables
        const done$ = new Subject<void>();
        // Listen for the image load error and fallback to the default
        const errorSub$ = fromEvent(this.fallBackImage.nativeElement, 'error')
            .pipe(
                takeUntil(done$),
                tap(() => {
                    // Fallback to the default icon
                    this.setCssVar('--font-icon-svg-img', `url(${this.getPathToSvg(FONT_ICON_DEFAULT)})`);
                })
            );
        // Listen for the correct image load and clear the error subscription
        const loadSub$ = fromEvent(this.fallBackImage.nativeElement, 'load')
            .pipe(
                takeUntil(done$),
            );
        /**
         * Subscribe to both streams. When either complete both streams are
         * unsubscribed.
         */
        merge(errorSub$, loadSub$).subscribe(() => {
            // Once the image has loaded remove the DOM element
            this.fallBackImage.nativeElement.remove();
            // Trigger the unsubscribe for the load and error observers
            done$.next();
        });

        // If the src is provided, we apply it directly
        this.imgSrc = this.svgSrc ? this.svgSrc : this.getPathToSvg(this.name);

        // Set the css var
        this.setCssVar('--font-icon-svg-img', `url(${this.imgSrc})`);
    }

    private getPathToSvg(name) {
        return `${this.configService.getCdnUrl()}${this.configService.getValue(CONFIG_CONSTANTS.svgPathKey)}/${name}.svg`;
    }

    private findValue(prop: string, value: string) {
        let result;

        // Sometimes the value comes in as a block character. We have to convert it back to a entity
        if(value.length === 1) {
            value = `&#${value.charCodeAt(0)};`;
        }

        // Loop through our object and find the name value based on the prop given
        const webFont = Object.values(WEB_FONT_VALUES).find(webFontValue => {
            if (webFontValue[prop] === value) {
                // Log a warning so we can correct this value with a name
                this.logger.warn(`${this.COMPONENT_NAME} ${this.windowReference.location.href} is using a ${prop}` +
                ` of ${value}. It should be replaced with ${webFontValue.name}`);

                return true;
            }
        });

        if (webFont) {
            result = webFont.name;
        }

        return result;
    }

    private setCssVar(name, value) {
        this.elementReference.style.setProperty(name, value);
    }
}
